<template>
  <div class="button-group-fixed">
    <btn-menu
      :main-menu-open="mainMenuOpen"
      @togglemenu="$emit('togglemenu')"
    />
    <btn-round
      :icon="gpsButtonIcon"
      @clicked="$emit('resetmap')"
    />
    <btn-balance
      v-if="shouldDisplayBalanceButton === true"
      :balance="userBalance"
    />
    <btn-round
      v-if="enableHelpBtn === true"
      :icon="`/themes/${theme}/images/icons/ico-info.svg`"
      :width="100"
      :height="100"
      @clicked="$emit('closeRules')"
    />
  </div>
</template>

<script>
import BtnMenu from './BtnMenu.vue';
import BtnRound from './BtnRound.vue';
import BtnBalance from './BtnBalance.vue';

export default {
  components: { BtnMenu, BtnRound, BtnBalance },
  props: {
    userBalance: {
      type: Number,
      required: true,
    },
    gpsButtonIcon: {
      type: String,
      required: true,
    },
    enableLogin: {
      type: Boolean,
      required: true,
    },
    mainMenuOpen: {
      type: Boolean,
      required: true,
    },
    theme: {
      type: String,
      required: false,
      default: 'dotmap',
    },
    enableHelpBtn: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    shouldDisplayBalanceButton() {
      return this.enableLogin === true;
    },
  },
};
</script>
