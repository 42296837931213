import http from './fetch';

export const getMessages = async (gameApiUrl, teamUuid, token) => {
    const url = `${gameApiUrl}/modules/chat/messages/for-team/${teamUuid}`;
    const res = await http.getJSON(url, {}, token);
    return res;
  };

export const postMessage = async (gameApiUrl, message, teamToken) => {
    const url = `${gameApiUrl}/modules/chat/messages`;
    const res = await http.postJSON(url, {}, message, teamToken);
    return res;
  };