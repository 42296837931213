<template>
  <transition name="fadeout">
    <initial-loading v-if="!loaded" />
    <div
      v-else
      class="wrapper"
      :class="wrapperClass"
    >
      <div class="backdrop" />
      <lightbox-container
        :show="shouldShowLightBox === true"
        :picture-path="lightboxPicture.pictures ? lightboxPicture.pictures : ''"
        :pictures="lightboxPicture.pictures"
        :initial-index="lightboxPicture.index"
        @hide="hideLightbox"
      />
      <the-nav
        :theme="theme"
        :open="mainMenuOpen"
        @togglemenu="toggleMainMenu"
      />
      <solde-component
        v-if="destination.platformType === 'citypoly'"
        :new-balance="cityflouz"
      />
      <button-group
        v-if="shouldShowMainMenu === true"
        :user-balance="team ? team.score : -1"
        :gps-button-icon="`/themes/${theme}/images/icons/ico-paperplane.svg`"
        :enable-login="appConfig.enableLogin"
        :main-menu-open="mainMenuOpen"
        :enable-help-btn="destination.enableHelpButton"
        :theme="theme"
        @togglemenu="toggleMainMenu"
        @resetmap="resetMap()"
        @close-rules="displayRules = false"
      />
      <div
        v-else
        class="button-group-fixed"
      >
        <btn-round
          :icon="`/themes/${theme}/images/icons/ico-paperplane.svg`"
          @clicked="resetMap()"
        />
        <btn-round
          v-if="destination.enableHelpButton === true"
          :icon="`/themes/${theme}/images/icons/ico-info.svg`"
          :width="100"
          :height="100"
          @clicked="onClickInfo"
        />
        <btn-round
          v-if="destination.platformType === k.PLATFORM_TYPE.CITYPOLY"
          :icon="`/themes/${theme}/images/icons/ico-home.svg`"
          :width="30"
          :height="30"
          @clicked="displayCitypolyProperties = true"
        />
        <div
          class="messages-container"
          @click="onDisplayChat"
        >
          <btn-round
            :icon="`/themes/${theme}/images/icons/ico-chat.svg`"
            :width="30"
            :height="30"
          />
          <div
            v-if="wsStore && wsStore.hasNewMessage"
            class="messages-puce"
          />
        </div>
      </div>
      <div class="main">
        <!-- Alert for Citypoly -->
        <team-block-alert
          v-if="teamIsBlocked"
          :show="teamIsBlocked"
          :title="$t('map.teamBlocked.title')"
          :content="$t('map.teamBlocked.blockDuration')"
          :block-until="teamBlockEndDate"
          @timer-is-over="refreshData()"
        />
        <!-- Alert for Citypoly -->
        <TeamGoodAlert
          :show="teamReceivedRent"
          :message="$t('map.teamGoodAlert.rentRecieved')"
          :close-timeout="30"
          @close="teamReceivedRent = false"
        />
        <div class="section-map section-map--short">
          <the-map
            v-if="destination"
            ref="map"
            :initial-coordinates="[destination.longitude, destination.latitude]"
            :initial-zoom="destination.zoom"
            :gps-mode="gpsMode"
            :should-display-content="shouldDisplayContent"
            @maploaded="onMapLoaded"
          />
          <div class="section__actions">
            <rectangle-button
              v-if="shouldDisplayFilterButton === true"
              :title="filterButtonText"
              faded
              :icon="`/themes/${theme}/images/icons/ico-location-blue.svg`"
              @click="filterButtonClicked"
            />
            <rectangle-button
              v-if="gpsMode !== 'off' && gpsEnabled === true"
              :title="gpsButtonText"
              @click="gpsButtonClicked"
            />
          </div>
        </div>
      </div>
      <categories-menu
        v-if="categoriesMenuOpen === true"
        :expanded="categoriesMenuOpen === true"
        @close="categoriesMenuOpen = false"
      />
      <!--<card-manager /> -->
      <city-panel
        v-if="destination.platformType !== 'citypoly'"
        :should-display-content="shouldDisplayContent"
      />
      <panel-container
        :poi="currentPOI"
        :closest-poi="closestPoi"
        :expanded="poiPanelOpen"
        :hide-checkin-popup="destination.hideCheckinPopup"
        @go-to-poi="goToPoi"
      />
      <transition name="rules">
        <GameFaq
          v-if="displayRules === true"
          :faq-text="destination.faqText"
          :phone-number="party ? party.phoneNumber : ''"
          @close="displayRules = false"
        />
      </transition>
      <transition name="slideinfo">
        <PropertyTitlesList
          v-if="displayCitypolyProperties === true"
          @close="displayCitypolyProperties = false"
        />
      </transition>
      <transition name="slideinfo">
        <ChatComponent
          v-if="displayChat === true"
          @close="displayChat = false"
        />
      </transition>
    </div>
  </transition>
</template>

<script setup>
import Swal from 'sweetalert2';
import * as k from '../constants';
import * as Sentry from '@sentry/vue';
import InitialLoading from '../components/map/InitialLoader.vue';
import TheNav from '../components/TheNav.vue';
import RectangleButton from '../components/ButtonRectangle.vue';
import ButtonGroup from '../components/ButtonGroup.vue';
import CategoriesMenu from '../components/categories/CategoriesMenu.vue';
import TheMap from '../components/TheMap.vue';
import CityPanel from '../components/map/PanelCity.vue';
import PanelContainer from '../components/map/PanelContainer.vue';
import LightboxContainer from '../components/image/LightboxContainer.vue';
import BtnRound from '../components/BtnRound.vue';
import GameFaq from '../components/map/GameFaq.vue';
import TeamBlockAlert from '../components/map/TeamBlockAlert.vue';
import TeamGoodAlert from '../components/map/TeamGoodAlert.vue';
import { getPoiByUuid } from '../api/pois';
import SoldeComponent from '../components/citypoly/SoldeComponent.vue';
import PropertyTitlesList from '../components/citypoly/PropertyTitlesList.vue';
import ChatComponent from '../components/chat/ChatComponent.vue';
import { useConfigStore } from '../pinia/configuration';
import { useWebSocketStore } from '../pinia/websocket';
import { computed, onMounted, ref, useTemplateRef, watch } from 'vue';
import { useDestinationStore } from '../pinia/destination';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useUIStore } from '../pinia/uistate';
import { useGameStore } from '../pinia/game';
import { usePoiStore } from '../pinia/poi';
const route = useRoute();
const router = useRouter();

const uiStore = useUIStore();
const gameStore = useGameStore();
const poiStore = usePoiStore();
const { t } = useI18n();
const appConfig = useConfigStore().appConfig;
const wsStore = useWebSocketStore();
const destinationStore = useDestinationStore();

const gpsMode = ref(k.GPS_MODE_OFF);
const platformType = ref(k.PLATFORM_TYPE.CITYPOLY);
const categoriesMenuOpen = ref(false);
const mainMenuOpen = ref(false);
const displayRules= ref(false);
const isRefreshingData = ref(false);
const initialLoadingDone = ref(false);
const displayCitypolyProperties = ref(false);
const teamReceivedRent = ref(false);
const displayChat = ref(false);
const destination = ref( destinationStore.currentDestination);
const mapRef = useTemplateRef('map')
const dataRefresher = ref(null);

const poiPanelOpen = computed(() => uiStore.poiPanelOpen);
const lightboxPicture = computed(() => uiStore.lightboxPicture);
const currentPOI = computed(() => poiStore.currentPoi);
const team = computed(() => gameStore.currentTeam);
const userLocationError =  computed(() => poiStore.loca);
const party =  computed(() => gameStore.currentParty);
const cityflouz =  computed(() => gameStore.currentTeam.score);
const citypolyPropertyTitles =  computed(() => gameStore.citypolyPropertyTitles);

const backdrop = computed(() => uiStore.hasOpenPanel);
const placesObject = computed(() => poiStore.poisObject);
const pois = computed(() => poiStore.activePois);
const teamIsBlocked= computed(() => gameStore.teamIsBlocked);
const teamBlockEndDate  = computed(() => gameStore.teamBlockEndDate);

const lang = computed(() => appConfig.lang);
const loaded = computed(() => initialLoadingDone.value === true && destination.value !== null);
const theme = computed(() => appConfig.cssTheme);
const teamAuthenticated = computed(() =>  gameStore.currentTeam !== null);
const shouldDisplayContent = computed(() => {
  if (appConfig.restrictContentToPlayers) {
    if (!team.value) {
      return false;
    }
  return true;
  }
  return true;
});



const closestPoi = computed(() => {
  if (pois.value.length >0 && pois.value[0].distance <= appConfig.baseCheckinDistance) {
    return pois.value[0];
  }
  return null
});
const shouldShowLightBox = computed(() => lightboxPicture.value !== null && lightboxPicture.value.pictures != null);

const shouldShowMainMenu = computed(() => {
  if (destination.value.platformType === k.PLATFORM_TYPE.CITYPOLY) {
    return false;
  }
  if (
    appConfig.enableLogin === false &&
    appConfig.enableRegistration === false
  ) {
    return false;
  }
  return true;
});

const shouldDisplayFilterButton = computed(() => {
  if (destination.value.platformType === k.PLATFORM_TYPE.CITYPOLY) {
    return false;
  }
  if (gpsMode.value === k.GPS_MODE_OFF) {
    return appConfig.enableCategoriesList;
  }
  return true;
});

const gpsEnabled = computed(() => appConfig.enableGeolocation);

const gpsButtonText = computed(() => {
  if (gpsMode.value === k.GPS_MODE_ON) {
    return t('map.buttons.gps_stop');
  }
  return t('map.buttons.gps_start');
});
const filterButtonText = computed(() => {
  if (gpsMode.value === k.GPS_MODE_OFF) {
    return t('map.buttons.place_filter');
  }
  return t('map.buttons.place_filter_map');
});
const wrapperClass = computed(()=> {
  let className = '';
  if (mainMenuOpen.value === true) {
    className += ' navigation-displayed';
  }
  if (backdrop.value === true) {
    className += ' has-visible-backdrop';
  }
  return className;
});


watch(route, () => {
  dispatchRoute();
})
watch(userLocationError, () => {
  if (userLocationError.value === true) {
    Swal.fire({
      title: t('map.noGeolocation.alertTitle'),
      text: t('map.noGeolocation.alertText'),
      icon: 'warning',
    });
  }
});
const dispatchRoute = ()=> {
      // loaded server-side as the result of Facebook Shares
  switch (route.name) {
    case 'placeView':
      gpsMode.value = k.GPS_MODE_OFF;
        showPlace(route.params.placeId);

      break;
    case 'placeRoutePreview':
      gpsMode.value = k.GPS_MODE_PREVIEW;
      placeRoutePreview(route.params.poiId);
      break;
    case 'placeRoute':
      gpsMode.value = k.GPS_MODE_ON;
      placeRoutePreview(route.params.poiId);
      break;

    default:
      gpsMode.value = k.GPS_MODE_OFF;
      poiStore.currentPoi = null;
      uiStore.closePOIPanel();
      mapRef.value.drawDefaultPOIS();
      mapRef.value.hideGuidingRoute();
      break;
  }
};
const placeRoutePreview = (placeIdentifier) => {
  const place = placesObject.value[placeIdentifier];
  if (place) {
    poiStore.currentPoi = place;
    uiStore.closePOIPanel();
    mapRef.value.drawGuidingRoute();
  }
};
const showPlace = (placeIdentifier) => {
    const place = placesObject.value[placeIdentifier];
    if (place) {
      poiStore.currentPoi = place;
      uiStore.openPOIPanel();
    } else {
      router.push('/');
    }

  mapRef.value.hideGuidingRoute();
};

const filterButtonClicked = () => {
  if (gpsMode.value === k.GPS_MODE_OFF) {
    categoriesMenuOpen.value = true;
  } else if (gpsMode.value === k.GPS_MODE_ON || gpsMode.value === k.GPS_MODE_PREVIEW) {
    gpsMode.value = k.GPS_MODE_OFF;
    router.push({ name: 'placeView', params: { placeId: route.params.poiId } });
  }
};

const resetMap = () => {
  mapRef.value.centerMap();
};

const hideLightbox = () => {
  uiStore.lightboxPicture = ({ pictures: null, index: 0 });
};
const gpsButtonClicked = () => {
  if (gpsMode.value === k.GPS_MODE_PREVIEW) {
    router.push({ name: 'placeRoute', params: { poiId: route.params.poiId } });
  } else {
    router.back();
  }
};
const toggleMainMenu = () => {
  mainMenuOpen.value = !mainMenuOpen.value;
};

const onMapLoaded = () => {
  dispatchRoute();
};

const onClickInfo = () => {
  displayRules.value = true;
};



const goToPoi = async (poiUuid) => {
  try {
    router.push({ name: 'placeRoutePreview', params: { poiId: poiUuid } });
  } catch (error) {
    console.log('enable to fetch poi', error);
    Sentry.captureException(error);
  }
};

const refreshData = async() => {
  if (isRefreshingData.value === true) {
    return;
  }
  isRefreshingData.value = true;

  // fetch the destination
  let dest = null;
  try {
    dest = await destinationStore.fetchDestination();
    if (!dest) {
      throw new Error('could not fetch initial destination.');
    }
  } catch (error) {
    isRefreshingData.value = false;
    console.log('ERROR DURING DESTINATION FETCH', error);
    Sentry.captureException(error);
    return;
  }
  const siteId = dest.id;
  // fetch the other assets
  try {
    await Promise.all([
      categoriesStore.fetchCategories(siteId, appConfig.lang),
      poiStore.getPois(),
    ]);
    if (appConfig.platformType !== k.PLATFORM_TYPE.CITYPOLY) {
      await gameStore.loadTeamContext();
    } else {
      await gameStore.fetchCurrentTeam();
      await gameStore.getPropertyTitles();
    }
    initialLoadingDone.value = true;
  } catch (error) {
    if (
      teamAuthenticated.value === false &&
      appConfig.restrictContentToPlayers === true &&
      appConfig.enableRegistration === true &&
      route.name !== 'Register'
    ) {
      initialLoadingDone.value = true;
      isRefreshingData.value = false;
      router.push({ name: 'Register' });
      return;
    }
  }
  try {
    if (
      teamAuthenticated.value === false &&
      appConfig.restrictContentToPlayers === true &&
      appConfig.enableRegistration === true
    ) {
      router.push({ name: 'Register' });
    }

    initialLoadingDone.value = true;
    isRefreshingData.value = false;
  } catch (error) {
    loaded.value = true;
    isRefreshingData.value = false;
    gameStore.logout();
    console.log('ERROR DURING INITIAL LOADING', error);
  } finally {
    isRefreshingData.value = false;
    initialLoadingDone.value = true;
  }
};

const citypolySseActions = () =>  {
  const eventSource = new EventSource(
    `${appConfig.gameApiBaseUrl}/modules/citypoly/parties/${party.value.id}/events`
  );
  eventSource.addEventListener('dmp', async (event) => {
    const sseEventData = JSON.parse(event.data);
    if (
      sseEventData.actionType === k.TEAM_ACTIONS.RENT_RECIEVED &&
      citypolyPropertyTitles.value.length <= 0
    ) {
      return;
      } else {
        const propertyTitleTargeted = citypolyPropertyTitles.value.find((propertyTitle) => {
        return propertyTitle.poiId === sseEventData.poiId;
      });
      if (!propertyTitleTargeted) {
        return;
      } else {
        teamReceivedRent.value = true;
        await gameStore.fetchCurrentTeam();
      }
    }
  });
};

const onDisplayChat = () => {
  displayChat.value = true;
  wsStore.hasNewMessage = false;
};

onMounted(async() => {
  if (appConfig.enableGeolocation === true) {
    if (userLocationError.value === true) {
      Swal.fire({
        title: t('map.noGeolocation.alertTitle'),
        text: t('map.noGeolocation.alertText'),
        icon: 'warning',
      });
    }
    poiStore.startLocationWatcher();
  }
  await refreshData();
  platformType.value = appConfig.platformType;
  dataRefresher.value = setInterval(async () => {
    await refreshData();
  }, appConfig.dataUpdateInterval * 1000);
  if (destination.value.platformType === k.PLATFORM_TYPE.CITYPOLY && party.value) {
    citypolySseActions();
  }
});
</script>
<style lang="scss" scoped>
.rules-enter-active,
.rules-leave-active {
  transition: opacity 0.6s;
}
.rules-enter,
.rules-leave-to {
  opacity: 0;
}

.fadeout-enter-active,
.fadeout-leave-active {
  transition: opacity 0.6s;
}
.fadeout-enter-from,
.fadeout-leave-to {
  opacity: 0;
}
.slideinfo-leave-active,
.slideinfo-enter-active {
  transition: 0.4s ease-in-out;
}
.slideinfo-enter-from {
  transform: translateY(100%);
  margin-top: 0px;
}
.slideinfo-leave-to {
  transform: translateY(100%);
}
.messages-container {
  margin-top: 1rem;
  position: relative;
  cursor: pointer;
}
.messages-puce {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: -5px;
  right: -5px;
}
</style>
