<template>
  <transition name="slideup">
    <panel-poi-container
      v-if="poi && expanded"
      :poi="poi"
      @close="closePOIPanel"
      @checkindone="onCheckinDone"
    />
    <panel-checkin
      v-else-if="shouldDisplayCheckinPopup === true"
      :poi="closestPoi"
      :expanded="true"
      @close="inhibitCheckinPopup = true"
      @checkinstart="onCheckinInProgress"
      @checkincancelled="onCheckinCancelled"
      @checkindone="onCheckinDone"
    />
    <PanelCitypolyWelcome
      v-else-if="shouldDisplayCitypolyWelcome && !shouldDisplayDice"
      :expanded="shouldDisplayCitypolyWelcome"
      :current-team="team"
      @display-dice-pannel="displayDice"
      @go-to-poi="citypolyRedirectActions"
      @display-lucky-card="displayLuckyCard"
    />
    <DiceComponent
      v-else-if="shouldDisplayDice && !teamIsBlocked"
      @new-action="citypolyRedirectActions"
    />
    <PanelLuckyCard
      v-else-if="shouldDisplayLuckyCard && !teamIsBlocked"
      :current-team="team"
      @new-action="citypolyRedirectActions"
      @blocked="blockedTeam"
    />
    <panel-poi-container
      v-else-if="shouldDisplayCitypolyPannelPoi"
      :poi="activePoi"
      @close="closePOIPanel"
      @checkindone="onCheckinDone"
      @roll-dice="citypolyRedirectActions"
      @reject="citypolyRedirectActions"
    />
  </transition>
</template>

<script>
/*
 * NOTE ON THE CHECKIN POPUP
 * the expected behavior of the checkin popup is the following:
 * - When the user has closed the popup, do not reopen until the user goes away and comes back
 * - When checkin is done, close the popup and open the POI one
 * - When loading the map, do not open the popup (performance issue)
 * - when in game mode, do not open the popup if checkin done on this place
 */

import * as Sentry from '@sentry/vue';
import PanelPoiContainer from './PanelPoiContainer.vue';
import PanelCheckin from './PanelCheckin.vue';
import PanelCitypolyWelcome from '../../components/citypoly/PanelCitypolyWelcome.vue';
import DiceComponent from '../citypoly/PanelDice.vue';
import { postBuyReject } from '../../api/citypoly';
import { PLATFORM_TYPE, TEAM_ACTIONS } from '../../constants';
import PanelLuckyCard from '../citypoly/PanelLuckyCard.vue';
import { useConfigStore } from '../../pinia/configuration';
import { useGameStore } from '../../pinia/game';
import { useDestinationStore } from '../../pinia/destination';
import { usePoiStore } from '../../pinia/poi';
import { UseCheckinControl } from '../../composables/CheckinControl';
import { basicConfetti, sadConfetti } from '../../tools/CanvasConfetti';
const STATE_GUIDE_POI = 'STATE_GUIDE_POI';
const STATE_LOGIN = 'STATE_LOGIN';
const STATE_CHECKIN = 'STATE_CHECKIN';
const STATE_CHECKIN_DONE = 'STATE_CHECKIN_DONE';
const STATE_CLOSED = 'STATE_CLOSED';

export default {
  components: {
    PanelCheckin,
    PanelPoiContainer,
    PanelCitypolyWelcome,
    DiceComponent,
    PanelLuckyCard,
  },
  props: {
    poi: {
      type: Object,
      required: false,
      default: () => null,
    },
    closestPoi: {
      type: Object,
      required: false,
      default: () => null,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
    hideCheckinPopup: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['goToPoi'],
  setup() {
    const destinationStore = useDestinationStore();
    const appConfig = useConfigStore().appConfig;
    const gameStore = useGameStore();
    const poiStore = usePoiStore();
    const { canCheckin, checkinDone, isWithinCheckinDistance } = UseCheckinControl();
    return { appConfig, gameStore, destinationStore, poiStore, canCheckin, checkinDone, isWithinCheckinDistance};
  },
  data() {
    return {
      hideBackButtonOnEvent: false,
      initialView: true,
      inhibitCheckinPopup: false,
      previousCheckinPopupID: -1,
      blockCheckinPopupDuringLoading: true,
      checkinInProgress: false,
      shouldDisplayDice: false,
      shouldDisplayLuckyCard: false,
      displayWelcome: false,
      activePoi: null,
      isLoading: true,
    };
  },
  computed: {

    pois() {
      return this.poiStore.activePois;
    },
    currentPOI() {
      return this.gameStore.currentPoi;
    },
    poisList() {
      return this.poiStore.pois;
    },
    teamIsBlocked() {
      return this.gameStore.teamIsBlocked;
    },
    team() { return this.gameStore.currentTeam },
    token() { return this.gameStore.currentToken },
    animationName() {
      return 'slideleft';
    },
    shouldDisplayCheckinPopup() {
      if (this.teamIsBlocked) {
        return false;
      }
      if (this.appConfig.platformType === PLATFORM_TYPE.CITYPOLY) {
        return false;
      }
      if (this.closestPoi === null) {
        return false;
      }
      if (this.hideCheckinPopup === true) {
        return false;
      }
      if (this.inhibitCheckinPopup === true) {
        return false;
      }
      if (this.checkinInProgress === true) {
        return true;
      }
      if (this.closestPoi) {
        const checkinExists = this.gameStore.checkinsByPOI[this.closestPoi.uuid];
        if (checkinExists) {
          return false;
        }
      }
      if (this.blockCheckinPopupDuringLoading === true) {
        return false;
      }
      return true;
    },
    currentDestination() {
      return this.destinationStore.currentDestination;
    },
    // cityPoly
    poiTargetIsAvailable() {
      if (this.currentDestination.platformType !== PLATFORM_TYPE.CITYPOLY) {
        return false;
      }
      if (!this.currentPOI) {
        return false;
      }
      const targetPoi = this.pois.find((p) => {
        return p.uuid === this.currentPOI.uuid;
      });
      if (targetPoi.distance <= this.appConfig.baseCheckinDistance) {
        return true;
      }
      return false;
    },
    shouldDisplayCitypolyWelcome() {
      if (this.$route.name !== 'home') {
        return false;
      }
      if (this.appConfig.platformType === PLATFORM_TYPE.CITYPOLY && this.displayWelcome) {
        return true;
      }
      return false;
    },
    shouldDisplayCitypolyPannelPoi() {
      if (this.teamIsBlocked) {
        return false;
      }
      if (!this.team) {
        return false;
      }
      if (this.isLoading === true) {
        return false;
      }
      if (this.poiTargetIsAvailable) {
        return true;
      }
      return false;
    },
    hasPOI() {
      return this.poi !== null || this.closestPoi !== null;
    },
    isExpanded() {
      return this.expanded || this.closestPoi !== null;
    },
    btnLink() {
      const route = {};
      if (!this.poi) {
        return route;
      }
      switch (this.panelState) {
        case STATE_LOGIN:
          route.name = 'Login';
          break;
        case STATE_GUIDE_POI:
          route.name = 'placeRoutePreview';
          route.params = { poiId: this.poi.id };
          break;
        case STATE_CHECKIN_DONE:
          route.name = 'placeRoutePreview';
          route.params = { poiId: this.poi.id };
          break;
        case STATE_CHECKIN:
          break;
        default:
          route.name = 'placeRoutePreview';
          route.params = { poiId: this.poi.id };
          break;
      }
      return route;
    },
    btnText() {
      let text = '';
      switch (this.panelState) {
        case STATE_GUIDE_POI:
          text = this.$t('components.PanelContainer.button.start_gps');
          break;
        case STATE_LOGIN:
          text = this.$t('components.PanelContainer.button.checkin_authenticate');
          break;
        case STATE_CHECKIN_DONE:
          text = this.$t('components.PanelContainer.button.checkin_done');
          break;
        case STATE_CHECKIN:
          text = this.$t('components.PanelContainer.button.checkin');
          break;
        default:
          text = this.$t('components.PanelContainer.button.start_gps');
          break;
      }
      return text;
    },
    panelState() {
      if (this.poi) {
        if (!this.isWithinCheckinDistance(this.poi)) {
          return STATE_GUIDE_POI;
        }
        if (!this.authenticated) {
          return STATE_LOGIN;
        }
        if (this.checkinDone(this.poi)) {
          return STATE_CHECKIN_DONE;
        }
        if (this.canCheckin(this.user, this.poi)) {
          return STATE_CHECKIN;
        }
        return STATE_GUIDE_POI;
      }
      return STATE_CLOSED;
    },
  },
  watch: {
    closestPoi(newPOI, oldPOI) {
      // ClosestPOI did not change so probably just a refreh from the WS
      if (!oldPOI) {
        return;
      }
      if (this.closestPoi !== null && newPOI.id === oldPOI.id) {
        return;
      }
      if (this.closestPoi === null || newPOI.id !== oldPOI.id) {
        this.inhibitCheckinPopup = false;
      }
      if (this.closestPoi !== null) {
        this.previousCheckinPopupID = this.closestPoi.id;
      }
    },
    poi(nPoi) {
      if (!this.activePoi && this.appConfig.platformType === PLATFORM_TYPE.CITYPOLY) {
        this.activePoi = nPoi;
      }
    },
    teamIsBlocked(newV, oldV) {
      if (oldV === true && newV === false) {
        this.displayDice();
      }
    }
  },
  async mounted() {
    if (this.appConfig.platformType === PLATFORM_TYPE.CITYPOLY) {
      setTimeout(() => {
        this.displayWelcome = true;
        this.isLoading = false;
      }, 500);
      return;
    }
    setTimeout(() => {
      this.blockCheckinPopupDuringLoading = false;
    }, 4000);
  },
  methods: {
    closePOIPanel() {
      this.$router.push('/');
    },
    onCheckinInProgress() {
      this.checkinInProgress = true;
    },
    onCheckinDone(evt) {
      this.runConfetti(evt.score);
      if (this.$route.name !== 'placeView') {
        this.$router.push({ name: 'placeView', params: { placeId: evt.poiId } });
      }
      this.checkinInProgress = false;
      this.inhibitCheckinPopup = true;
    },
    onCheckinCancelled() {
      this.checkinInProgress = false;
    },
    runConfetti(score) {
      if (score < 0) {
        this.$toast.open({
          message: `<strong>${this.$t('urbanrace.toastTitleBadPlace')}</strong> ${this.$t(
            'urbanrace.toastContentBadPlace',
            { points: Math.abs(score) }
          )}`,
          type: 'default',
          pauseOnHover: false,
          position: 'bottom',
          duration: 5000,
        });
      } else if (this.currentDestination.platformType === 'urbanrace') {
        this.$toast.open({
          message: `<strong>${this.$t('urbanrace.toastTitleSuccess')}</strong> ${this.$t(
            'urbanrace.toastContentSuccess',
            { points: Math.abs(score) }
          )}`,
          type: 'success',
          pauseOnHover: false,
          position: 'bottom',
          duration: 5000,
        });
      }
      if (score >= 0) {
        basicConfetti();

      } else {
        sadConfetti();
      }
    },
    displayDice() {
      setTimeout(() => {
        this.shouldDisplayDice = true;
        this.$router.push('/');
      }, 300);
    },
    displayLuckyCard() {
      setTimeout(() => {
        this.shouldDisplayLuckyCard = true;
        this.displayWelcome = false;
        this.shouldDisplayDice = false;
        this.$router.push('/');
      }, 300);
    },
    async blockedTeam() {
      this.shouldDisplayLuckyCard = false;
      await this.gameStore.fetchCurrentTeam();

    },
    async citypolyRedirectActions(action) {
      if (action.type !== TEAM_ACTIONS.LUCKY) {
        this.shouldDisplayLuckyCard = false;
      }
      switch (action.type) {
        case TEAM_ACTIONS.GO:
          try {
            await this.gameStore.fetchCurrentTeam();
          } catch (error) {
            console.log(error);
            Sentry.captureException(error);
          }
          this.activePoi = this.poisList.find((p) => {
            return p.uuid === action.targetId;
          });
          this.poiStore.currentPoi = this.activePoi;
          this.shouldDisplayDice = false;
          this.$emit('goToPoi', action.targetId);
          break;
        case TEAM_ACTIONS.BUY_CHALLENGE:
          if (
            !this.poiTargetIsAvailable ||
            this.currentPOI.uuid !== this.team.currentAction.targetId
          ) {
            this.$emit('goToPoi', action.targetId);
          }
          this.displayWelcome = false;
          this.activePoi = this.currentPOI;
          break;
        case TEAM_ACTIONS.BUY:
          if (
            !this.poiTargetIsAvailable ||
            this.currentPOI.uuid !== this.team.currentAction.targetId
          ) {
            this.$emit('goToPoi', action.targetId);
          }
          this.displayWelcome = false;
          this.activePoi = this.currentPOI;
          break;
        case TEAM_ACTIONS.PAY:
          this.$emit('goToPoi', action.targetId);
          this.displayWelcome = false;
          this.activePoi = this.currentPOI;
          break;
        case TEAM_ACTIONS.REJECT:
          try {
            await postBuyReject(
              this.appConfig.gameApiBaseUrl,
              this.currentPOI.uuid,
              this.token,
            );
            this.activePoi = null;
            this.poiStore.currentPoi = null;
            this.shouldDisplayDice = true;
            this.$router.push('/');
          } catch (error) {
            console.log(error);
            Sentry.captureException(error);
          }
          break;

        case TEAM_ACTIONS.ROLL:
          this.activePoi = null;
          this.poiStore.currentPoi = null;
          this.shouldDisplayDice = true;
          this.$router.push('/');
          break;

        case TEAM_ACTIONS.LUCKY:
          this.activePoi = null;
          this.poiStore.currentPoi = null;
          this.displayLuckyCard()
          this.$router.push('/');
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style scoped>
.slideup-leave-active,
.slideup-enter-active {
  transition: 0.4s ease-in-out;
}
.slideup-enter-from {
  transform: translatey(100%);
  margin-top: 0px;
}
.slideup-leave-to {
  transform: translatey(100%);
}
.notify--target {
  overflow: hidden;
}
</style>
