<template>
  <Transition name="slide-top">
    <div
      v-if="open"
      class="error-popup"
    >
      <div class="error-popup__content">
        <div class="error-popup__icon">
          <img
            src="../assets/images/icons/alert-circle-outline.svg"
            alt="warning icon"
          >
        </div>
        <h2 class="error-popup__title">
          {{ title }}
        </h2>
        <p
          class="error-popup__message"
          v-html="message"
        />
      </div>
      <div class="error-popup__button">
        <button
          class="error-popup__button"
          @click="close"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </Transition>
</template>
<script setup>

defineProps({
  title: {
    type: String,
    required: true,
  },
  message: {
    type: String,
    required: true,
  },
  buttonText: {
    type: String,
    required: true,
  },
  open: {
    type: Boolean,
    required: false,
    default: false,
  }
});

const emit = defineEmits(['close']);

const close = () => {
emit('close');
};
</script>
<style scoped>
.error-popup {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 20px;
  background: orangered;
  text-align: left;
  color:white;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  z-index: 100;
}
.error-popup__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom:20px;
}
.error-popup__icon {
  display: inline-block;
  width: 60px;
  height: 60px;
}

.slide-top-enter-active {
  transition: all 0.3s ease-out;
}

.slide-top-leave-active {
  transition: all 0.3s ease-in;
}
.error-popup__button {
  background: white;
  color: orangered;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
}

.slide-top-enter-from,
.slide-top-leave-to {
  transform: translateY(-100px);
}
</style>
