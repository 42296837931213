<template>
  <div class="notify__department__section">
    <h3 class="notify__department__title notify__heading">
      {{ $t('components.PanelModuleAudio.title') }}
    </h3>

    <div class="notify__entry" v-for="d in audioDocuments" :key="d.id">
      <h5>{{ d.title }}</h5>
      <p> {{ d.description }}</p>
      <audio :src="d.url" controls>  Your browser does not support this audio file</audio>
      <p v-if="d.copyright"><em>&copy; {{d.copyright}}</em></p>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    audioDocuments: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style scoped>

h5 {

  font-size: 1em;
}
em {
  font-size: 0.8em;
}
</style>
