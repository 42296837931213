import { ref } from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios';
import axiosRetry from 'axios-retry';
axiosRetry(axios, { retries: 3 });

export const useDestinationStore = defineStore('destinations', () => {
  const currentDestination = ref(null);
  const fetchDestination = async () => {
    const res = await axios.get('/api/v2/destinations/default');
    currentDestination.value = res.data;
    return res.data;
  };
  return {
    currentDestination,
    fetchDestination,
  };
});

