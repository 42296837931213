import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import  Cookies  from 'js-cookie';

import { fetchTeamByToken, createTeam, fetchPartyById, fetchDisabledPlaces, fetchTeamLogs, fetchPropertyTitles, performCheckin, sendWelcomeEmail } from '../axiosapi/game';
import { useConfigStore } from './configuration';

import { COOKIE_NAME_TEAM, PLATFORM_TYPE } from '../constants';

export const useGameStore = defineStore('game', () => {
  const cfgStore = useConfigStore();
  const currentTeam = ref(null);
  const currentParty = ref(null);
  const disabledPlaces = ref([]);
  const teamLogs = ref([]);
  const citypolyPropertyTitles = ref([]);
  const currentToken = ref(null);
  cfgStore.fetchConfig();


  const teamIsBlocked = computed(() => {
    if (!currentTeam.value) {
      return false;
    }
    if (!currentTeam.value.blockUntil) {
      return false;
    }
    try {
      const blockDate = Date.parse(currentTeam.value.blockUntil);
      return isAfter(blockDate, new Date());
    } catch (error) {
      return false;
    }
  });

  const teamBlockEndDate = computed(() => {
    if (!currentTeam.value) {
      return null;
    }
    if (!currentTeam.value.blockUntil) {
      return null;
    }
    return Date.parse(currentTeam.value.blockUntil);
  });

  const checkinsByPOI = computed(() => {
    if (!currentTeam.value) {
      return {};
    }
    if (!Array.isArray(currentTeam.value.events) || currentTeam.value.events.length === 0) {
      return {};
    }
    const poiIds = {};
    currentTeam.value.events.forEach((e) => {
      if (e.type === 'checkin' || e.type === 'qrcode') {
      if (poiIds[e.poiId]) {
        poiIds[e.poiId].push(e);
      } else {
        poiIds[e.poiId] = [e];
      }
    }
    });
    return poiIds;
  })

  const logout = () => {
    currentTeam.value = null;
    currentParty.value = null;
    currentToken.value = null;
    disabledPlaces.value = [];
    citypolyPropertyTitles.value = [];
    Cookies.remove(COOKIE_NAME_TEAM);
  };
  const getTeamByToken = async (teamToken) => {

    const team = await fetchTeamByToken(cfgStore.appConfig.gameApiBaseUrl, teamToken, cfgStore.appConfig.platformType === PLATFORM_TYPE.CITYPOLY);
    currentTeam.value = team;
    currentToken.value = teamToken;
    setTeamCookie(teamToken);
    return team;
  };

  const fetchCurrentTeam = async () => {
    const token = Cookies.get(COOKIE_NAME_TEAM);
    if (!token) {
      currentTeam.value = null;
      return null;
    }
    const team = await getTeamByToken(token);
    currentTeam.value = team;
    return team;
  };

  const loadTeamContext = async() => {
    const team = await fetchCurrentTeam();
    if (!team) {
      return;
    }
    await Promise.all([
      getCurrentParty(),
      getDisabledPlaces(),
      getTeamLogs(),
    ]);
  }

  const getTeamLogs = async () => {
    if (!currentTeam.value) {
      return;
    }
    const logs = await fetchTeamLogs(cfgStore.appConfig.gameApiBaseUrl, currentTeam.value.id, currentToken.value);
    teamLogs.value = logs;
    return logs;
  };

  const getDisabledPlaces = async () => {
    if (!currentTeam.value) {
      return;
    }
    const places = await fetchDisabledPlaces(cfgStore.appConfig.gameApiBaseUrl, currentToken.value);
    disabledPlaces.value = places;
    return places;
  }

  const addTeam = async (team) => {
    const newTeam = await createTeam(cfgStore.appConfig.gameApiBaseUrl, team);
    currentTeam.value = newTeam;
    setTeamCookie(newTeam.token);
    return newTeam;
  };

  const resendWelcomeEmail = async (email, gameId) => {
    return await  sendWelcomeEmail({ email, partyId: gameId });
  };

  const getCurrentParty = async () => {
    if (!currentTeam.value) {
      return null;
    }
    const party = await fetchPartyById(cfgStore.appConfig.gameApiBaseUrl, currentTeam.value.partyId);
    currentParty.value = party;
    return party;
  };

  const getPropertyTitles = async () => {
    const res = await fetchPropertyTitles(cfgStore.appConfig.gameApiBaseUrl, currentToken.value);
    citypolyPropertyTitles.value = res;
    return res;
  };

  const doCheckin = async ({ name,  poiId, longitude, latitude, points}) => {
    await performCheckin(cfgStore.appConfig.gameApiBaseUrl, {
      name,
      teamId: currentTeam.value.id,
      partyId: currentTeam.value.partyId,
      poiId,
      longitude,
      latitude,
      points,

    }, correnttoken.value);
    await fetchCurrentTeam();
    //TODO
  }

  const setTeamCookie = (token) => {
    Cookies.set(COOKIE_NAME_TEAM, token, { expires: 180, SameSite: 'Strict' });
  }



  return {
    currentTeam,
    currentParty,
    currentToken,
    disabledPlaces,
    citypolyPropertyTitles,
    teamIsBlocked,
    teamBlockEndDate,
    checkinsByPOI,
    logout,
    fetchTeamByToken,
    fetchCurrentTeam,
    getCurrentParty,
    addTeam,
    loadTeamContext,
    getPropertyTitles,
    doCheckin,
    resendWelcomeEmail,
  };
});
