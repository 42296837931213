<template>
  <figure class="survey-image">
    <full-screen-image
      :image-src="imageSrc"
      @close="$emit('close')"
    />
  </figure>
</template>
<script>
import FullScreenImage from '../image/FullScreenImage.vue';

export default {
  components: {
    FullScreenImage,
  },
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
figure.survey-image {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}
</style>
