import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

export const useUIStore = defineStore('ui', () => {
  // panels
  const poiPanelOpen = ref(false);
  const cityPanelOpen = ref(false);

  // lightbox for fullscreen picture
  const lightboxPicture = ref({ pictures: null});
  const openPOIPanel = () => {
    if (cityPanelOpen.value === true) {
      cityPanelOpen.value = false;
    }
    poiPanelOpen.value = true;
  }
  const closePOIPanel = () => {
    poiPanelOpen.value = false;
  }

  const hasOpenPanel = computed(() => poiPanelOpen.value === true || cityPanelOpen.value === true);
  return {
    poiPanelOpen, cityPanelOpen, lightboxPicture, hasOpenPanel, openPOIPanel, closePOIPanel,
  };

    
});
