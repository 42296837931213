<template>
  <div class="lightbox-button-container">
  <button
      @click.prevent="$emit('btnpressed')"
      :class="`lightbox-button lightbox-${icon}`">
      <span
        v-if="icon !== 'left' && icon !== 'right'"
        class="lighbox-button-help">
        {{text}}
      </span>
  </button>

  </div>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: 'some text here',
    },
  },
};
</script>
<style lang="scss">

button.lightbox-button {
    position: absolute;
    background-color: rgba(0,0,0,0.66);
    box-shadow: 0px 0px 1px rgba(20, 20, 20, 0.40), 0px 1px 1px rgba(0, 0,0, 0.40);
    border: none;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(0,0,0,0.66);
    border-radius: 50%;
    z-index: 3;

    width: 30px;
    height: 30px;
    &:hover {
      .lighbox-button-help {
        display: inline-block;
        animation: fade-in .3s linear 1;
        -webkit-animation: fade-in .3s linear 1;
        -moz-animation: fade-in .3s linear 1;
        -ms-animation: fade-in .3s linear 1;
      }
    }
}
button.lightbox-close {
    background-size: 12px 12px;
    background-image: url('/images/icons/ico-cross.svg');
    top: 15px;
    right: 15px;
}

button.lightbox-left {
    background-size: 12px 12px;
    background-image: url('/images/icons/arrow-left.svg');
    top: 47%;
    left: 15px;
}
button.lightbox-right {
    background-size: 12px 12px;
    background-image: url('/images/icons/arrow-right.svg');
    top: 47%;
    right: 15px;
}
button.lightbox-fullscreen {
    background-size: 21px 21px;
    background-image: url('/images/icons/ico-fullscreen.svg');
    top: 15px;
    left: 15px;
    span.lighbox-button-help {
      background-color: rgba(0,0,0,0.66);
      box-shadow: 0px 0px 1px rgba(20, 20, 20, 0.40), 0px 1px 1px rgba(0, 0,0, 0.40);
      border-radius: 5px;
      color: white;
      display:none;
      height: 30px;
      position: absolute;
      left: 100%;
      width: 100px;
      margin-left: 10px;
      top: 0px;
      margin-top: auto;
      line-height: 30px;
}
}
button.lightbox-info {
    background-size: 21px 21px;
    background-image: url('/images/icons/ico-info.svg');
    bottom: 15px;
    left: 15px;
    span.lighbox-button-help {
   background-color: rgba(0,0,0,0.66);
   box-shadow: 0px 0px 1px rgba(20, 20, 20, 0.40), 0px 1px 1px rgba(0, 0,0, 0.40);
  border-radius: 5px;
  color: white;
  display:none;
  height: 30px;
  position: absolute;
  left: 100%;
  width: 100px;
  margin-right: 10px;
  top: 0px;
  margin-top: auto;
  line-height: 30px;
}
}

span.lighbox-button-help {
   background-color: rgba(0,0,0,0.66);
   box-shadow: 0px 0px 1px rgba(20, 20, 20, 0.40), 0px 1px 1px rgba(0, 0,0, 0.40);
  border-radius: 5px;
  color: white;
  display:none;
  height: 30px;
  position: absolute;
  right: 100%;
  width: 100px;
  margin-right: 10px;
  top: 0px;
  margin-top: auto;
  line-height: 30px;
}
@keyframes fade-in {
  from   { opacity: 0; }
  to { opacity: 1; }
}
@-webkit-keyframes fade-in {
  from   { opacity: 0; }
  to { opacity: 1; }
}
@-moz-keyframes fade-in {
  from   { opacity: 0; }
  to { opacity: 1; }
}
@-ms-keyframes fade-in {
  from   { opacity: 0; }
  to { opacity: 1; }
}
</style>
