<template>
  <v-lazy-image
    v-if="lazy"
    :srcset="sourceSet"
    :src="url(baseWidth)"
    :src-placeholder="lowResUrl(baseWidth)"
    :width="baseWidth"
    :height="baseWidth / ratio"
  />

  <img
    v-else
    :srcset="sourceSet"
    :src="url(baseWidth)"
    :width="baseWidth"
    :height="baseWidth / ratio"
    alt="place descriptive image"
  >
</template>

<script setup>
import VLazyImage from 'v-lazy-image';
import { computed } from 'vue';
import { useConfigStore } from '../pinia/configuration';

/**
 * breakpoints for the supported images sizes.
 * a srcset image will be generated for each value.
 */

const screenWidths = [
  360,
  480,
  600,
  720,
  840,
  960,
  1024,
  1280,
  1440,
  1600,
  1920,
  2048,
  2536,
  3072,
];
const pixelDensities = [1, 2, 3, 4];

const tabletBreakpoint = 768;
/**
 *
 * @prop {Number} BaseWidth: the base width of the picture
 * @prop {Number} ratio: the width/height ratio of the picture
 * @prop {String} picturePath: the path of the picture on our servers
 * @prop {Boolean} usePixelDensity: if set to true, the srcset will generate pictures
 *  with the specified dimensions @1x, @2x, @3x, @4x
 *
 *  This is usefull for pictures which have a fixed width
 */
const appConfig = useConfigStore().appConfig;


const props = defineProps({
  baseWidth: {
    type: Number,
    required: false,
    default: 100,
  },
  ratio: {
    type: Number,
    required: false,
    default: 2.75,
  },
  picturePath: {
    type: String,
    required: true,
  },
  usePixelDensity: {
    type: Boolean,
    required: false,
    default: false,
  },
  lazy: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const sourceSet = computed(() => {
  const urls = [];
      if (props.usePixelDensity === true) {
        pixelDensities.forEach((multiplier) => {
          urls.push(`${url(props.baseWidth * multiplier)} ${multiplier}x`);
        });
        return urls.join(',');
      }

      screenWidths.forEach((width) => {
        let imgWidth = width - 60;
        if (width >= tabletBreakpoint) {
          imgWidth = width - 200;
        }
        urls.push(`${url(imgWidth)} ${width}w`);
      });
      return urls.join(',');
});

const url = (width) => {
  if (appConfig.useCDN === false) {
    return props.picturePath;
  }
  const height = Math.ceil(width / props.ratio);
  return `https://afdbbajlen.cloudimg.io/crop/${width}x${height}/tjpg/${encodeURI(props.picturePath)}`;
};

const lowResUrl = (width) => {
      if (appConfig.useCDN === false) {
        return '';
      }
      const height = Math.ceil(width / props.ratio);
      return `https://afdbbajlen.cloudimg.io/crop/${width}x${height}/q2/${encodeURI(this.picturePath)}`;
    };
</script>
<style scoped>
.v-lazy-image {
  filter: blur(5px);
  transition: filter 0.3s;
  will-change: filter;
}
.v-lazy-image-loaded {
  filter: blur(0);
}

</style>
