import * as Sentry from '@sentry/vue';
import { useGameStore } from './pinia/game';

const UserAuthenticated  = (to, from, next) => {
  const gameStore = useGameStore();
  if (gameStore.currentTeam) {
    next();
    return;
  }
    next({ name: 'Login' });
};

export default {
  UserAuthenticated,
};
