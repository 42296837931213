<template>
  <div class="notify__department__section">
    <h3 class="notify__department__title notify__heading">{{ title }}</h3>
    <div class="notify__entry" v-html="content">
    </div>
  </div>

</template>

<script>
export default {
  name: 'PanelModuleDescription',
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
};
</script>
