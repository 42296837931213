<template>
  <a href="#" @click.prevent="$emit('clicked');" :class="buttonClasses">
    <img v-if="icon !== null" :src="icon" :width="width" :height="height" alt="">
    <slot name="btn-extension"></slot>
  </a>
</template>

<script>
export default {
  props: {
    classes: {
      type: String,
      required: false,
      default: '',
    },
    width: {
      type: Number,
      required: false,
      default: 18,
    },
    height: {
      type: Number,
      required: false,
      default: 18,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    buttonClasses() {
      return `btn-round ${this.classes}`;
    },
  },
};
</script>
