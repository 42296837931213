import http from './fetch';

// Team
const getMe = async (gameApiUrl, teamToken) => {
  const url = `${gameApiUrl}/modules/citypoly/me`;
  const res = await http.getJSON(url, {}, teamToken);
  return res;
};

// dice
const rollDice = async (gameApiUrl, coords, teamToken) => {
  const url = `${gameApiUrl}/modules/citypoly/roll`;
  const res = await http.postJSON(url, {}, coords, teamToken);
  return res;
};

// checkin
const checkinCitypoly = async (gameApiUrl, poiId, coords, teamToken) => {
  const url = `${gameApiUrl}/modules/citypoly/checkin/${poiId}`;
  const res = await http.postJSON(url, {}, coords, teamToken);
  return res;
};

// Buy challenge
const getPoiChallenge = async (gameApiUrl, poiId, teamToken) => {
  const url = `${gameApiUrl}/gameapi/v1/simple-questions/for-poi/${poiId}/unanswered`;
  const res = await http.getJSON(url, {}, teamToken);
  return res;
};

const postAnswerBuyChallenge = async (gameApiUrl, poiId, answer, teamToken) => {
  const url = `${gameApiUrl}/modules/citypoly/buy/challenge/${poiId}`;
  const res = await http.postJSON(url, {}, answer, teamToken);
  return res;
};

// Buy Poi
const postBuyPoi = async (gameApiUrl, poiId, teamToken) => {
  const url = `${gameApiUrl}/modules/citypoly/buy/${poiId}`;
  const res = await http.postJSON(url, {}, {}, teamToken);
  return res;
};

// refusing to buy
const postBuyReject = async (gameApiUrl, poiId, teamToken) => {
  const url = `${gameApiUrl}/modules/citypoly/buy/reject/${poiId}`;
  const res = await http.postJSON(url, {}, {}, teamToken);
  return res;
};

//Pay rent
const postPayRent = async (gameApiUrl, poiId, teamToken) => {
  const url = `${gameApiUrl}/modules/citypoly/rent/${poiId}`;
  const res = await http.postJSON(url, {}, {}, teamToken);
  return res;
};

//Lucky card
const getLuckyCard = async (gameApiUrl, teamToken) => {
  const url = `${gameApiUrl}/modules/citypoly/card`;
  const res = await http.getJSON(url, {}, teamToken);
  return res;
};

//Properies list
const fetchPropertyTitle = async (gameApiUrl, teamToken) => {
  const url = `${gameApiUrl}/modules/citypoly/property-titles`;
  const res = await http.getJSON(url, {}, teamToken);
  return res;
}
//mortgage
const putMortagingPoi = async (gameApiUrl, poiId, teamToken) => {
  const url = `${gameApiUrl}/modules/citypoly/mortgage/${poiId}`;
  const res = await http.putJSON(url, {}, {}, teamToken);
  return res;
};

const deleteMortgagedByPoiId = async (gameApiUrl, poiId, teamToken) => {
  const url = `${gameApiUrl}/modules/citypoly/mortgage/${poiId}`;
  const res = await http.deleteJSON(url, {}, teamToken);
  return res;
}

// change teamAction

const debugAction = async (gameApiUrl, actionRequest, teamToken) => {
  const url = `${gameApiUrl}/modules/citypoly/debug/action`;
  const res = await http.postJSON(url, {}, actionRequest, teamToken);
  return res;
}

export {
  getMe,
  rollDice,
  checkinCitypoly,
  getPoiChallenge,
  postBuyReject,
  postAnswerBuyChallenge,
  postBuyPoi,
  postPayRent,
  getLuckyCard,
  fetchPropertyTitle,
  putMortagingPoi,
  deleteMortgagedByPoiId,
  debugAction
};
