<template>
  <div class="notify__department__section">
    <h3 class="notify__department__title notify__heading">
      {{ title }}
    </h3>

    <div class="notify__entry">
      <ul>
        <li
          v-for="c in content"
          :key="c.url"
        >
          <a
            v-if="c.urlType === 'facebook'"
            :href="c.url"
            target="_blank"
          ><facebook-icon /> Facebook
          </a>
          <a
            v-if="c.urlType === 'website'"
            :href="c.url"
            target="_blank"
          ><web-icon />Site internet
          </a>
          <a
            v-if="c.urlType === 'instagram'"
            :href="c.url"
            target="_blank"
          ><instagram-icon />Instagram
          </a>
          <a
            v-if="c.urlType === 'twitter'"
            :href="c.url"
            target="_blank"
          ><twitter-icon />Twitter
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import FacebookIcon from 'vue-material-design-icons/Facebook.vue';
import WebIcon from 'vue-material-design-icons/Web.vue';
import InstagramIcon from 'vue-material-design-icons/Instagram.vue';
import TwitterIcon from 'vue-material-design-icons/Twitter.vue';

export default {

  name: 'PanelModuleWebsites',
  components: {
    FacebookIcon,
    WebIcon,
    InstagramIcon,
    TwitterIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
  .notify__entry {
    & li {
      list-style-type: none;
      & a {
        margin-top: 15px;
        margin-bottom: 15px;
        padding: 20px 40px;
        border: 1px solid #999;
        border-radius: 8px;
        font-size: 1rem;
        padding-left: 15px;
        margin-right: 20px;
        display: inline-block;
        width: 100%;
        &span {
          width:48px;
          height: 49px;
          font-size: 50px;
          margin-right: 25px;
          display: block;
        }

      }
    }
  }
  .material-design-icon.icon-2x {
  height: 2em;
  width: 2em;
}

.material-design-icon.icon-2x > .material-design-icon__svg {
  height: 2em;
  width: 2em;
}
</style>
