<template>
  <round-button
    classes="btn-menu"
    :class="mainMenuOpen ? 'is-activated' : ''"
    @clicked="$emit('togglemenu')">
    <template v-slot:btn-extension>
        <span class="btn__icon">
          <span></span>
          <span></span>
          <span></span>
        </span>
    </template>
  </round-button>
</template>
<script>

import RoundButton from './BtnRound.vue';

export default {
  components: { RoundButton },
  props: {
    mainMenuOpen: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
