<template>
<div class="lightbox-picture">
    <resp-image
        :base-width="baseWidth"
        :picture-path="picturePath"
        :ratio="ratio"
        :alt-text="altText">
    </resp-image>
</div>
</template>

<script>

export default {
  props: {
    baseWidth: {
      type: Number,
      required: false,
      default: 100,
    },
    ratio: {
      type: Number,
      required: false,
      default: 1.78,
    },
    picturePath: {
      type: String,
      required: true,
    },
    altText: {
      type: String,
      required: false,
      default: 'Illustrative picture of this place',
    },
  },
};
</script>
