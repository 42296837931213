<template>
  <img
    :srcset="srcSet"
    :src="url"
    :alt="altText"
    :width="baseWidth"
    :height="baseWidth * ratio"
    @load="$emit('load')"
    @error="$emit('error')"
  >
</template>

<script>
import { useConfigStore } from '../../pinia/configuration';
const pixelDensities = [1, 2, 3, 4];

/**
 *
 * A responsive image that uses cloudImage to generate urls for different pixel densities.
 *
 * @prop {Number} BaseWidth: the base width of the picture
 * @prop {Number} ratio: the width/height ratio of the picture (default: 1.78 which is 16/9)
 * @prop {String} picturePath: the path of the picture on our servers
 * @prop {String} altText: The text to fill the alt attribute of the image
 */
export default {
  props: {
    baseWidth: {
      type: Number,
      required: false,
      default: 100,
    },
    ratio: {
      type: Number,
      required: false,
      default: 1.5,
    },
    picturePath: {
      type: String,
      required: true,
    },
    altText: {
      type: String,
      required: false,
      default: 'Illustrative picture of this place',
    },
    mode: {
      type: String,
      required: false,
      default: 'crop',
    },
  },
  setup() {
    const appConfig = useConfigStore().appConfig;
    return { appConfig };
  },
  data() {
    return {
      url: null,
      srcSet: null,
    };
  },
  computed: {
    useLocalProxy() {
      return this.appConfig.useLocalImageProxy;
    },
    /*
    sourceSet() {
      const urls = pixelDensities.map((x) => `${this.computeUrl(this.baseWidth * x)} ${x}x`);
      return urls.join(',');
    },
    url() {
      return this.computeUrl(this.baseWidth);
    },
    */
  },
  watch: {
    picturePath() {
      this.url = this.computeUrl(this.baseWidth);
      this.srcSet = this.computeSourceSet();
    },
    url: {
      handler() {
        this.url = this.computeUrl(this.baseWidth);
        this.srcSet = this.computeSourceSet();
      },
      immediate: true,
    },
    baseWidth: {
      handler() {
        this.url = this.computeUrl(this.baseWidth);
        this.srcSet = this.computeSourceSet();
      },
      immediate: true,
    },
  },
  methods: {
    computeUrl(width) {
      if (this.appConfig.useCDN === false) {
        return this.picturePath;
      }
      const height = Math.ceil(width / this.ratio);
      if (this.useLocalProxy === true) {
        return `https://img-testing.pitc.io/${width}x${height},jpg/${encodeURI(this.picturePath)}`;
      }
      if (this.mode === 'fit') {
        return `https://afdbbajlen.cloudimg.io/v7/${encodeURI(this.picturePath)}?width=${width}&func=${this.mode}`;
      }
      return `https://afdbbajlen.cloudimg.io/v7/${encodeURI(this.picturePath)}?width=${width}&height=${height}&func=${this.mode}`;
    },
    computeSourceSet() {
      const urls = pixelDensities.map((x) => `${this.computeUrl(this.baseWidth * x)} ${x}x`);
      return urls.join(',');
    },
  },
};
</script>
