<template>
  <div
    class="close-btn"
  >
    <img
      src="../assets/images/icons/ico-close-pict.svg"
      alt="button that closes the card"
    >
  </div>
</template>

<script setup>
</script>

<style scoped>
.close-btn {
  position: relative;
  bottom: 50%;
  left: 90%;
  width: 25px;
  height: 25px;
}
</style>
