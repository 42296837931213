// stores/websocket.js
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useWebSocket } from '@vueuse/core';
import { WS_ACTIONS } from '../constants';
import { useGameStore } from './game';

export const useWebSocketStore = defineStore('websocket', () => {
  const chatMessages = ref([]);
  const status = ref('DISCONNECTED');
  const hasNewMessage = ref(false);
  const gameStore = useGameStore();
  let webSocket;

  const connect = (wsUrl) => {
    webSocket = useWebSocket(wsUrl, {
      immediate: true,
      autoReconnect: {
        retries: 3,
        delay: 5000,
        onFailed() {
          console.log('WebSocket reconnection failed');
        },
      },
      heartbeat: {
        message: 'ping',
        interval: 15000,
        pongTimeout: 2000,
      },
      onConnected() {
        console.log('WebSocket connected.');
        status.value = 'CONNECTED';
      },
      onDisconnected() {
        console.log('WebSocket disconnected.');
        status.value = 'DISCONNECTED';
      },
      onError(ws, evt) {
        console.error('WebSocket error: ', evt);
        status.value = 'ERROR';
      },
      onMessage(ws, evt) {
        if (evt.data !== null && !evt.data.includes(WS_ACTIONS.PING)) {
          const wsData = JSON.parse(evt.data);
          const currentTeamId = gameStore.currentTeam.id;
          if (wsData && (currentTeamId === wsData.toUserId ||
            wsData.kind === WS_ACTIONS.BROADCAST ||
            currentTeamId === wsData.fromUserId
          )) {
            chatMessages.value = [...chatMessages.value, wsData];
            hasNewMessage.value = true;
          }
        }
      },
    });
  };

  return {
    status,
    connect,
    hasNewMessage,
    chatMessages
  };
});
