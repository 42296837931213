<template>
  <div
    class="notify__button is-active js-notify-toggler clickable"
  >
    {{ title }}
    <span v-if="subtitle"> <br>{{ subtitle }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>
