<template>
  <transition name="fade">
    <div
      v-if="show === true"
      class="map-alert map-alert-good"
    >
      <div>
        <p class="map-alert-title">
          {{ $t("map.teamGoodAlert.title") }}
        </p>
        <p>{{ message }}</p>
      </div>

      <div
        class="close-alert clickable"
        @click="emit('close')"
      >
        <img
          :src="`/themes/${appConfig.cssTheme}/images/icons/ico-close-transparent.svg`"
          :width="25"
          :height="25"
          alt="close-svg"
        >
      </div>
    </div>
  </transition>
</template>

<script setup>
import { onMounted } from 'vue';
import { useConfigStore } from '../../pinia/configuration';
const appConfig = useConfigStore().appConfig;
const emit = defineEmits(['close']);

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  message: {
    type: String,
    required: true,
  },
  closeTimeout: {
    type: Number,
    required: true,
  },
});

onMounted(() => {
  setTimeout(() => {
    emit('close');
  }, props.closeTimeout);
});
</script>

<style lang="scss" scoped>
.map-alert-good {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
