<template>
  <nav
    class="nav"
    :class="open ? 'is-expanded' : '' "
  >
    <header class="nav__header">
      <a
        href="#"
        class="logo"
      >
        <img
          :src="logoSrc(theme)"
          :srcset="logoSrcSet(theme)"
          height="40"
          alt="partner logo"
        >
      </a>
    </header><!-- /.nav__header -->
    <div class="nav__content">
      <ul class="nav__links">
        <li class="current">
          <a
            href="/"
            @click.prevent="goTo"
          >
            <img
              :src="`/themes/${theme}/images/icons/ico-home.svg`"
              width="25"
              height="24"
              alt="icon-home"
            >
            {{ $t('components.TheNav.home') }}</a>
        </li>
        <li v-if="authenticated === false && appConfig.enableRegistration === true">
          <a
            href="/register"
            @click.prevent="goTo"
          >
            <img
              :src="`/themes/${theme}/images/icons/ico-avatar.svg`"
              width="25"
              height="24"
              alt="icon-home"
            >
            {{ $t('components.TheNav.register') }}</a>
        </li>
        <li v-if="authenticated === false && appConfig.enableLogin === true">
          <a
            href="/login"
            @click.prevent="goTo"
          >
            <img
              :src="`/themes/${theme}/images/icons/ico-avatar.svg`"
              width="25"
              height="24"
              alt="icon-home"
            >
            {{ $t('components.TheNav.login') }}</a>
        </li>

        <li
          v-if="authenticated === true
            && appConfig.enableLogin === true"
        >
          <a
            href="/account"
            @click.prevent="goTo"
          >
            <img
              :src="`/themes/${theme}/images/icons/ico-avatar.svg`"
              width="24"
              height="26"
              alt="icon-bookmarks"
            >
            {{ $t('components.TheNav.account') }}
          </a>
        </li>

        <li v-if="authenticated === true">
          <router-link
            :to="{ name: 'home'}"
            @click="logout"
          >
            <img
              :src="`/themes/${theme}/images/icons/ico-exit.svg`"
              width="26"
              height="26"
              alt="icon-logout"
            >
            {{ $t('components.TheNav.logout') }}
          </router-link>
        </li>
      </ul>
    </div><!-- /.nav__content -->
  </nav><!-- /.nav -->
</template>

<script setup>
import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { logoSrc, logoSrcSet } from '../themingTools/logo';
import { useConfigStore } from '../pinia/configuration';
import { useGameStore } from '../pinia/game';


const emit = defineEmits(['togglemenu']);
const router = useRouter();
const route = useRoute();
const gameStore = useGameStore();
const configStore = useConfigStore();
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});

const appConfig = computed(() => configStore.appConfig);
const authenticated = computed(() => gameStore.currentTeam !== null);
const theme = computed(() => appConfig.value.cssTheme);

const goTo = (event) => {
  if (route.path !== event.srcElement.pathname) {
    router.push(event.srcElement.pathname);
  }
  emit('togglemenu');
};

const logout = () => {
  gameStore.logout();
  emit('togglemenu');
};

</script>
