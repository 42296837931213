<template>
  <div class="notify__department__section">
    <h3 class="notify__department__title notify__heading">{{ $t('panelModuleEndgame.title')}}</h3>

    <div class="notify__entry">
      <p class="text-center"> {{ $t('panelModuleEndgame.intro1') }}
        <strong class="big">{{ team.score }}</strong>
        {{ $t('panelModuleEndgame.introPoints') }}
      </p>
      <p> {{ $t('panelModuleEndgame.intro2') }}
      </p>
      <p class="text-center mt-1">
      <btn-rectangle
      v-if="showGift === false"
      :title="$t('panelModuleEndgame.btnTitle')"
      @click="displayGift"></btn-rectangle>
      </p>
      <div class="gift-wrapper" v-if="showGift === true">
        <p class="text-center"><strong>{{  currentGift }}</strong></p>
      </div>
    </div>
  </div>

</template>

<script>

/* eslint-disable max-len */

import celebration from 'canvas-confetti';
import BtnRectangle from '../ButtonRectangle.vue';

export default {
  name: 'PanelModuleEndgame',
  components: {
    BtnRectangle,
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
    team: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showGift: false,
    };
  },
  computed: {
    currentGift() {
      const { score } = this.team;
      let currentReward = this.$t('panelModuleEndgame.noGift');
      let currentRewardScore = -1;

      if (this.party.endgameRewards && this.party.endgameRewards.length > 0) {
        this.party.endgameRewards.forEach((reward) => {
          if (reward.minScore <= score && reward.minScore > currentRewardScore) {
            currentReward = reward.text;
            currentRewardScore = reward.minScore;
          }
        });
      }
      return currentReward;
    },
  },
  methods: {
    displayGift() {
      this.showGift = true;
      this.celebrate();
    },
    celebrate() {
      const heart = celebration.shapeFromPath({
        path: 'M167 72c19,-38 37,-56 75,-56 42,0 76,33 76,75 0,76 -76,151 -151,227 -76,-76 -151,-151 -151,-227 0,-42 33,-75 75,-75 38,0 57,18 76,56z',
        matrix: [0.03333333333333333, 0, 0, 0.03333333333333333, -5.566666666666666, -5.533333333333333],
      });
      const count = 200;
      const sizeMultiplier = 4;
      const left = {
        origin: { x: 0, y: 1.0 },
      };
      const right = {
        origin: { x: 1, y: 1.0 },
      };

      const colors = ['#bb0000', '#ffaeff', '#a82fa8'];

      function fire(particleRatio, defaults, opts) {
        celebration({
          ...defaults,
          ...opts,
          particleCount: Math.floor(count * particleRatio),
        });
      }

      fire(0.25, left, {
        spread: 26,
        scalar: 1 * sizeMultiplier,
        startVelocity: 55,
        shapes: [heart],
        colors,
      });
      fire(0.25, right, {
        spread: 26,
        scalar: 1 * sizeMultiplier,
        startVelocity: 55,
        shapes: [heart],
        colors,
      });

      fire(0.2, left, {
        spread: 60,
        shapes: [heart],
        colors,
        scalar: 1 * sizeMultiplier,

      });
      fire(0.2, right, {
        spread: 60,
        shapes: [heart],
        colors,
        scalar: 1 * sizeMultiplier,

      });
      fire(0.35, left, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8 * sizeMultiplier,
        shapes: [heart],
        colors,

      });
      fire(0.35, right, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8 * sizeMultiplier,
        shapes: [heart],
        colors,

      });
      fire(0.1, left, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2 * sizeMultiplier,
        shapes: [heart],
        colors,

      });
      fire(0.1, right, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2 * sizeMultiplier,
        shapes: [heart],
        colors,

      });

      fire(0.1, left, {
        spread: 120,
        startVelocity: 45,
        shapes: [heart],
        colors,
        scalar: 1 * sizeMultiplier,

      });
      fire(0.1, right, {
        spread: 120,
        startVelocity: 45,
        shapes: [heart],
        colors,
        scalar: 1 * sizeMultiplier,

      });
    },
  },

};
</script>
<style scoped>
.big {
  font-size: 1.5em;
}
.mt-1 {
  margin-top: 1Opx;
}
.gift-wrapper {
  margin-top: 10px;
  padding: 3px 3px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
}
</style>
