
/*
 * This file contains functions that retrieve the location information of a POI. It replaces the old POITranslator mixin.
 */


const extractLocaleField = (poi, lang, field) => {
  let title = '';
  let langFound = false;
  if (!poi.locales) {
    return '';
  }
  const locales = Object.keys(poi.locales);
  locales.forEach((l) => {
    if (l.substring(0, 2) === lang) {
      title = poi.locales[l][field];
      if (title) {
        langFound = true;
      }

    }
  });
    // We didn't find the original language, let's try to find the first language available
  if (langFound === true) {
    return title;
  }
  langFound = false;

  if (locales.length > 0) {
    locales.forEach((l) => {
      if (poi.locales[l][field] && langFound === false) {
        title = poi.locales[l][field];
        langFound = true;
      }
    });
    if (langFound === true) {
      return title;
    }
  }
  return '';
}

// poiTitle tries its best to find the title of the POI. If the title is not found in the requested language, it returns the first title found.
export const poiTitle = (poi, lang) => {
  return extractLocaleField(poi, lang, 'name');

};

// poiDescription tries its best to find the description of the POI. If the description is not found in the requested language, it returns the first description found.
export const poiDescription = (poi, lang) => {
  return extractLocaleField(poi, lang, 'description');
};

// poiSchedule tries its best to find the schedule of the POI. If the schedule is not found in the requested language, it returns the first schedule found.
export const poiSchedule = (poi, lang) => {
  return extractLocaleField(poi, lang, 'scheduleInformation');
};

// poiPictures returns a list of pictures of the POI. If the pictures are not found, it returns the default picture of the webapp.
export const poiPictures = (poi, basePicturePath) => {
  const picturesList = [];
  if (!poi.pictures) {
    return ['/img/default.jpg'];
  }
  poi.pictures.forEach((p) => {
    if (p.filename) {
      picturesList.push(`${basePicturePath}/${poi.siteId}/${p.filename}`);
    } else {
      picturesList.push('/img/default.jpg');
    }
  });
  if (picturesList.length === 0) {
    picturesList.push('/img/default.jpg');
  }
  return picturesList;
};
