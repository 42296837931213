import { format } from 'date-fns';

const formatDate = (date) => {
  // if (date === undefined) {
  //   return ' ';
  // }
  const parsed = Date.parse(date);
  const dateFormated = format(parsed, 'dd/MM/YYY HH:mm');
  return dateFormated;
};

export default formatDate;
