<template>
  <div class="notify__department__section">
    <h3 class="notify__department__title notify__heading">
      {{ title }}
    </h3>

    <div class="notify__entry">
      <ul>
        <li
          v-for="c in content"
          :key="c.url"
        >
          <a
            v-if="c.type === 'phone'"
            :href="`tel:${c.value}`"
          ><phone-icon />  {{ c.value }}</a>
          <a
            v-if="c.type === 'email'"
            :href="`mailto:${c.value}`"
          ><email-icon /> {{ c.value }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import PhoneIcon from 'vue-material-design-icons/Phone.vue';
import EmailIcon from 'vue-material-design-icons/Email.vue';

export default {

  name: 'PanelModuleWebsites',
  components: {
    PhoneIcon,
    EmailIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
  .notify__entry {
    & li {
      list-style-type: none;
      & a {
        margin-top: 15px;
        margin-bottom: 15px;
        padding: 20px 40px;
        border: 1px solid #999;
        border-radius: 8px;
        font-size: 1rem;
        padding-left: 15px;
        margin-right: 20px;
        display: inline-block;
        width: 100%;
        &span {
          width:48px;
          height: 49px;
          font-size: 50px;
          margin-right: 25px;
          display: block;
        }
      }
    }
  }
  .material-design-icon.icon-2x {
  height: 2em;
  width: 2em;
}

.material-design-icon.icon-2x > .material-design-icon__svg {
  height: 2em;
  width: 2em;
}
</style>
