import { ref } from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios';
import axiosRetry from 'axios-retry';
axiosRetry(axios, { retries: 3 });

export const useCategoriesStore = defineStore('categories', () => {
  const allCategories = ref([]);
  const activeCategories = ref([]); // currently selected categories when filtering


  const fetchCategories = async (siteId, lang) => {
    const res = await axios.get(`/api/sites/${siteId}/categories?lang=${lang}`);
    allCategories.value = res.data;
    return res.data;
  }
  return {
    allCategories,
    activeCategories,
    fetchCategories,
  };
});
