<template>
  <transition
    name="fadeout"
    appear
  >
    <div
      class="container"
    >
      <div class="logo">
        <img
          :src="`/themes/${theme}/images/citypoly_logo.svg`"
          alt="citypoly logo"
        >
      </div>
      <div class="welcome">
        <p>{{ $t(pfx + "welcome") }}</p>
      </div>
      <div class="btn-container">
        <button
          class="btn"
          @click="router.push({ name: 'home' })"
        >
          {{ $t(pfx + "goToMapView") }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();
const pfx = 'views.citypoly.welcome.';


const theme = 'citypoly';
</script>

<style lang="scss" scoped>
.container {
  height: 93vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  width: 100%;
  height:35vh;
  padding: 2rem;
  & img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.welcome {
  width: 100%;
  text-align: center;
  color: white;
}

.btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.fadeout-enter-active,
.fadeout-leave-active {
  transition: opacity 0.6s;
}
.fadeout-enter-from,
.fadeout-leave-to {
  opacity: 0;
}
</style>
