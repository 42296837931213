<template>
  <div
    id="notify-target"
    class="notify notify--target is-expanded"
  >
    <div
      id="dep-2"
      class="notify__department is-expanded"
    >
      <BtnClose
        v-if="closeBtn"
        class="pannel-btn-close"
        @click="$emit('close')"
      />
      <div
        class="notify__department__section notify__department__section--px-0 notify__department__section--leading text-center pannel-container"
      >
        <div class="title-header">
          <slot name="header" />
        </div>
      </div>
      <div
        v-if="item.pictures.length >= 1"
        class="pannel-picture"
      >
        <picture-btn
          class="pannel-picture-full-screen"
          icon="fullscreen"
          :text="$t('common.buttons.picture_fullscreen')"
          @btnpressed="openLightbox"
        />
        <img
          :src="`${config.imagesBaseUrl}/${item.siteId}/${item.pictures[0].filename}`"
          alt="poi image"
        >
      </div>
      <div
        id="notify__department__content_scroller"
        class="notify__department__content pannel-content"
      >
        <slot name="default" />
      </div>
    </div>
    <div class="notify__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup>
import PictureBtn from '../image/PictureBtn.vue';
import {useConfigStore} from '../../pinia/configuration';
import BtnClose from '../BtnClose.vue';
import { useUIStore } from '../../pinia/uistate';

defineEmits(['close']);
const config = useConfigStore().appConfig;

const uiStore = useUIStore();
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  itemType: {
    type: String,
    required: false,
    default: 'poi',
  },
  closeBtn: {
    type: Boolean,
    required: false,
    default: false,
  }
});

const openLightbox = () => {
  uiStore.lightboxPicture = { pictures: props.item.pictures, index: 0 };
};
</script>
<style lang="scss" scoped>

.pannel-container {
  position: relative;
  padding-top: 0;
}

.title-header {
  height: 5rem;
  background-image: linear-gradient(to right, #ffe55e, #ffcf57, #ffba55, #ffa557, #ff915b);
  display: flex;
  justify-content: center;
  align-items: center;
  & h2 {
    height: 100%;
    width: 100%;
  }
}
.pannel-content {
margin: 1rem 0;
}

.pannel-picture {
  height: 25vh;
  width: 100%;
  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  &-full-screen {
    position: absolute;
    top: 80px;
  }
}
.pannel-btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  cursor: pointer;
}
</style>
