<template>
  <div class="slider slider--locations notify__slider notify__header-mobile">
    <div class="slider__clip">
      <picture-btn
        icon="fullscreen"
        :text="$t('common.buttons.picture_fullscreen')"
        @btnpressed="openLightbox"
      />
      <responsive-image
        :picture-path="src.url"
        alt-text="Image de quiz"
        :base-width="375"
        :ratio="1.75"
      />
    </div>
  </div>
</template>

<script setup>
import PictureBtn from './PictureBtn.vue';
import ResponsiveImage from './ResponsiveImage.vue';
import { onMounted } from 'vue';
import { useUIStore } from '../../pinia/uistate';

const store = useUIStore();

const props = defineProps({
  imageSrc: {
    type: String,
    required: true
  }
});

const src = ref({ url: 'default.png', author: ''});

onMounted(() => {
  src.url = props.imageSrc;
});

const openLightbox = () => {
  store.lightboxPicture = { pictures: [this.src], index: 0 };
};
</script>
