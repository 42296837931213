import confetti from 'canvas-confetti';


const defaultColors= [
  '#1E90FF', //'DodgerBlue',
  '#6B8E23', // 'OliveDrab',
  '#FFD700', // 'Gold',
  '#FFC0CB', // 'Pink',
  '#6A5ACD', // 'SlateBlue',
  '#ADD8E6', // 'LightBlue',
  '#EE82EE', // 'Violet',
  '#98FB98', // 'PaleGreen',
  '#4682B4', // 'SteelBlue',
  '#F4A460', // 'SandyBrown',
  '#D2691E', // 'Chocolate',
  '#DC143C', // 'Crimson',
];

const greys =  [
  '#F5F5F5',
  '#708090',
  '#696969',
  '#000010',
  '#130E0A',
  '#EFEFEF',
  '#DCDCDC',
  '#CECECE',
  '#9E9E9E',
  '#808080',
  '#5A5E6B',
  '#2D241E',
];

export const basicConfetti = () => {
  confetti({
    particleCount: 300,
    spread:160,
    startVelocity: -35,
    origin: { y: -0.1 },
    colors: defaultColors,
  });
};


export const sadConfetti = () => {
  confetti({
    particleCount: 300,
    spread:160,
    startVelocity: -35,
    origin: { y: -0.1 },
    colors: greys,
  });
};



export const continuousConfetti = (duration = 3000, colors = defaultColors) => {
  const end = Date.now() + duration;

  (function frame() {
    confetti({
      particleCount: 12,
      angle: 60,
      spread: 55,
      origin: { x: 0, y: 1 },
      colors: colors,
    });
    confetti({
      particleCount: 12,
      angle: 120,
      spread: 55,
      origin: { x: 1, y: 1 },
      colors: colors,
    });

    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  })();
};

export const badNewsConfetti = (duration = 3000) => {
  const animationEnd = Date.now() + duration;
  let skew = 1;

  const randomInRange = (min, max) => Math.random() * (max - min) + min;

  const frame = () => {
    const timeLeft = animationEnd - Date.now();
    const ticks = Math.max(200, 500 * (timeLeft / duration));
    skew = Math.max(0.8, skew - 0.001);

    confetti({
      particleCount: 1,
      startVelocity: 0,
      ticks: ticks,
      origin: {
        x: Math.random(),
        y: Math.random() * skew - 0.2,
      },
      colors: ['#0000'],
      shapes: ['circle'],
      gravity: randomInRange(0.4, 0.6),
      scalar: randomInRange(0.4, 1),
      drift: randomInRange(-0.4, 0.4),
    });

    if (timeLeft > 0) {
      requestAnimationFrame(frame);
    }
  };
  frame();
};

export const colorfulConfetti = (colorsData = ['#bb0000', '#ffffff', '#00bb00', '#0000bb']) => {
  confetti({
    particleCount: 100,
    spread: 70,
    origin: { x: 0.5, y: 0.5 },
    colors: colorsData,
  });
};
