<template>
  <div class="categories-menu">
    <div class="category">
      <ul class="category-list">
        <category-item
          v-for="c in categories"
          :key="c.id"
          :category="c"
          :active="isActive(c)"
          :active-tags="activeTags(c)"
          @enable="enable(c)"
          @disable="disable(c)"
        />
      </ul>
    </div>
  </div>
</template>

<script setup>
import CategoryItem from './CategoryItem.vue';

const props = defineProps({
  categories: {
    type: Array,
    required: true,
  },
  activeCategories: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['save']);

const enable = (category) => {
  const newCategories = props.activeCategories.filter((c) => c.id !== category.id);
  newCategories.push(category);
  emit('save', newCategories);
};

const disable = (category) => {
  const newCategories = props.activeCategories.filter((c) => c.id !== category.id);
  emit('save', newCategories);
};

const isActive = (category) => {
  if (!props.activeCategories) {
    return false;
  }
  return props.activeCategories.some((c) => c.id === category.id);
};

const activeTags = (category) => {
  const activeCat = props.activeCategories.find((c) => c.id === category.id);
  return activeCat?.tags || [];
};
</script>
