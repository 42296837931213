<template>
  <span
    class="tag"
    :class="activeClass"
    @click="toggle"
  >
    {{ tag.name }}</span>
</template>

<script setup>
import { computed } from 'vue';

const emit = defineEmits(['enable', 'disable']);
const props = defineProps({
  isActive: {
    type: Boolean,
    required: false,
    default: false,
  },
  tag: {
    type: Object,
    required: true,
  },
});

const activeClass = computed(() => {
  if (isActive === true) {
    return 'is-dark';
  }
  return 'is-light';
});

const toggle = () => {
  if (!isActive) {
    emit('enable', tag.id);
  } else {
    emit('disable', tag.id);
  }
};
</script>

<style lang="sass" scoped>
@import "bulma/sass/utilities/_all.sass"
@import "bulma/sass/elements/tag.sass"

</style>
