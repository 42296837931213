import axios from 'axios';
import axiosRetry from 'axios-retry';

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

const buildHeaders = (headers = {}, token) => {
  const hd = { ...headers };
  if (token !== null || token !== undefined) {
    hd.Authorization = `Bearer ${token}`;
  }
  return hd;
};

const fetchWithTimeout = async (resource, options = {}) => {
  const { timeout = 15000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
};
/**
 * fetches non logged-in URLs
 * @param {String} url
 */

const fetchJSON = (url) => new Promise((resolve, reject) => {
  /*
  const reqOptions = {
    method: 'GET',
    mode: 'cors',
    cache: 'default',
  };
  */
  axios.get(url).then((response) => {
    resolve(response.data);
  }).catch((error) => {
    reject(error);
  });/*
  fetch(url, reqOptions).then((response) => {
    if (response.ok) {
      response.json().then((data) => {
        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    } else {
      reject(new Error(`invalid response code while fetching ${url}`));
    }
  }).catch((error) => {
    reject(error);
  });
  */
});

const getJSON = (URL, headersMap = {}, token) => new Promise((resolve, reject) => {
  axios.get(URL, {
    headers: buildHeaders(headersMap, token),
  }).then((response) => {
    resolve(response.data);
  }).catch((error) => {
    reject(error);
  });
});

const postJSON = (URL, headersMap = {}, body, token) => new Promise((resolve, reject) => {
  axios.post(URL, body, {
    headers: buildHeaders(headersMap, token),
  }).then((response) => {
    resolve(response.data);
  }).catch((error) => {
    reject(error);
  });
});

const putJSON = (URL, headersMap = {}, body, token) => new Promise((resolve, reject) => {
  axios.put(URL, body, {
    headers: buildHeaders(headersMap, token),
  }).then((response) => {
    resolve(response.data);
  }).catch((error) => {
    reject(error);
  });
});

const deleteJSON = (URL, headersMap = {}, token) => new Promise((resolve, reject) => {
  const reqOptions = {
    method: 'DELETE',
    headers: buildHeaders(headersMap, token),
    mode: 'cors',
    cache: 'default',
  };

  const req = new Request(URL, reqOptions);

  fetch(req, reqOptions).then((response) => {
    if (response.ok) {
      resolve();
    } else {
      reject(new Error(`failed response. status ${response.statusText}`));
    }
  }).catch((error) => { reject(error); });
});

const postImage = (URL, headersMap = {}, image, token) => new Promise((resolve, reject) => {
  const formData = new FormData();
  formData.append('picture', image);

  const reqOptions = {
    method: 'PUT',
    headers: buildHeaders(headersMap, token),
    body: formData,
    mode: 'cors',
    cache: 'default',
  };

  const req = new Request(URL, reqOptions);

  fetchWithTimeout(req, reqOptions).then((response) => {
    if (response.ok) {
      response.json().then((data) => { resolve(data); }).catch((error) => { reject(error); });
    } else {
      reject(new Error(`failed response. status ${response.statusText}`));
    }
  }).catch((error) => { reject(error); });
});

export default {
  fetchJSON,
  getJSON,
  postJSON,
  putJSON,
  deleteJSON,
  postImage,
};
