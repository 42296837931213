export const logoSrc = (themeName) => {
  return `/themes/${themeName}/images/logo.png`;
};

export const logoSrcSet = (themeName) => {
  return `/themes/${themeName}/images/logo@2x.png 2x, /themes/${themeName}/images/logo@3x.png 3x`;
};
/**
 * Secondary logo is used on secondary pages: login/register
 */
export const secondaryLogoSrc = (themeName) => {
  return `/themes/${themeName}/images/logo_secondary.png`;
};
/**
 * Secondary logo is used on secondary pages: login/register
 */

export const secondaryLogoSrcSet = (themeName) => {
  return `/themes/${themeName}/images/logo_secondary@2x.png 2x, /themes/${themeName}/images/logo_secondary@3x.png 3x`;
};
