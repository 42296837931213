<template>
  <div class="propertyTitle-title-container">
    <div class="propertyTitle-title-header">
      <h2>{{ $t(pfx + "title") }}</h2>
      <closeBtn
        :icon="`/themes/${theme}/images/icons/ico-close-transparent.svg`"
        :width="100"
        :height="100"
        :classes="'close'"
        @clicked="emit('close')"
      />
    </div>
    <div class="propertyTitle-title-list">
      <CardPropertyTitle
        v-for="poi in ownedPois"
        :key="poi.id"
        :poi="poi"
        :current-team-score="currentTeamScore"
        @remove-mortgage="removeMortgage"
      />
    </div>
  </div>
</template>

<script setup>
import closeBtn from '../BtnRound.vue';
import { computed, onMounted, ref } from 'vue';
import * as Sentry from '@sentry/vue';
import { deleteMortgagedByPoiId } from '../../api/citypoly';
import CardPropertyTitle from './CardPropertyTitle.vue';
import { useConfigStore } from '../../pinia/configuration';
import { useGameStore } from '../../pinia/game';
import { usePoiStore } from '../../pinia/poi';

const emit = defineEmits(['close']);
const appConfig = useConfigStore().appConfig;
const poiStore = usePoiStore();
const gameStore = useGameStore();
const pfx = 'components.citypoly.PropertyTitlesList.';
const token = gameStore.currentToken;
const currentTeamScore = ref(gameStore.currentTeam.score);
const displaySuccesAlert = ref(false);
const ownedPois = ref(null);
const propertyTitlesList = ref(gameStore.currentTeam.propertyTitlesList);

const theme = computed(() => {
  return appConfig.cssTheme ? appConfig.cssTheme : 'pitc';
});

const fetchCurrentTeamCitypoly = async () => {
  await gameStore.fetchCurrentTeam();
};

const getPropertiesByTeamId = async () => {
  try {
    let pois = Array.isArray(poiStore.activePois) ? poiStore.activePois : [];
    ownedPois.value = pois
      .filter((poi) =>
        propertyTitlesList.value.some((propertyTitle) => propertyTitle.poiId === poi.uuid)
      )
      .map((poi) => {
        const propertyTitle = propertyTitlesList.value.find(
          (propertyTitle) => propertyTitle.poiId === poi.uuid
        );
        return {
          ...poi,
          price: propertyTitle ? propertyTitle.price : 0,
          isMortgaged: propertyTitle.isMortgaged,
        };
      });
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

const removeMortgage = async (poiId) => {
  try {
    await deleteMortgagedByPoiId(appConfig.gameApiBaseUrl, poiId, token);
    displaySuccesAlert.value = true;
    setTimeout(() => {
      displaySuccesAlert.value = false;
    }, 3000);
    await getPropertiesByTeamId();
    await fetchCurrentTeamCitypoly();
  } catch (error) {
    console.log(error);
    Sentry.captureException(error);
  }
};

onMounted(async () => {
  await getPropertiesByTeamId();
});
</script>
<style lang="scss" scoped>
.propertyTitle-title-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  color: black;
  background-image: linear-gradient(to right, #ffe55e, #ffcf57, #ffba55, #ffa557, #ff915b);
  z-index: 100;
  overflow-y: scroll;
}
.propertyTitle-title-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  & h2 {
    color: #1a1a1a;
    margin-top: 0.5rem;
  }
}
.propertyTitle-title-phone {
  padding: 2rem;
  width: 100vw;
  & h1 {
    font-size: 3rem;
  }
  & h2 {
    font-size: 2rem;
  }
  & h3 {
    font-size: 1.5rem;
  }
  & ul > li {
    list-style: inside;
  }
  & ol {
    margin-left: 1rem;
  }
  & .ql-align-justify {
    text-align: left;
  }
  & .ql-align-right {
    text-align: end;
  }
  & .ql-align-center {
    text-align: center;
  }
}
.propertyTitle-title-list {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.close {
  background-color: rgb(89, 89, 89);
}
</style>
