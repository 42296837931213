<template>
  <transition name="fade">
    <div
      v-if="show === true"
      class="map-alert"
    >
      <p class="map-alert-title">
        {{ props.title }}
      </p>
      <p>{{ message }}</p>
    </div>
  </transition>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';

const emit = defineEmits(['timerIsOver']);

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  content: {
    type: String,
    required: true,
  },
  blockUntil: {
    type: Number,
    required: true,
  }
});

let timerInterval = null;
const message = ref(null);
const localBlockUntil = ref(null);

const refreshMessage = () => {
  if (localBlockUntil.value === null) {
    message.value = '';
    return;
  }

  const now = new Date();
  const duration = (localBlockUntil.value - now) / 1000;

  if (duration <= 0) {
    emit('timerIsOver');
    message.value = '';
    clearInterval(timerInterval);
    return;
  }

  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration % 60);

  message.value = minutes > 0
    ? props.content + `${minutes}m ${seconds}s`
    : props.content + `${seconds}s`;
};

onMounted(() => {
  localBlockUntil.value = props.blockUntil;
  timerInterval = setInterval(refreshMessage, 1000);
});

onUnmounted(() => {
  clearInterval(timerInterval);
});
</script>
