<template>
  <panel-poi
    v-if="shouldDisplayPoi"
    :poi="poi"
    @close="closePOIPanel"
    @checkindone="$emit('checkindone', $event)"
  />
  <PannelCitypolyPoi
    v-else-if="shouldDisplayCitypolyPoi"
    :poi="poi"
    @roll-dice="$emit('rollDice', roll)"
    @reject="$emit('reject', reject)"
  />
</template>

<script>
import PanelPoi from './PanelPoi.vue';
import PannelCitypolyPoi from '../citypoly/PanelCitypolyPoi.vue';
import { PLATFORM_TYPE, TEAM_ACTIONS } from '../../constants';
import { useConfigStore } from '../../pinia/configuration';


export default {
  components: {
    PanelPoi,
    PannelCitypolyPoi,
  },
  props: {
    poi: {
      type: Object,
      required: false,
      default: () => null,
    },
  },

  emits: ['close', 'checkindone', 'rollDice', 'reject'],
  setup() {
    const appConfig = useConfigStore().appConfig;
    return { appConfig };
  },
  data() {
    return {
      hideBackButtonOnEvent: false,
      roll: { type: TEAM_ACTIONS.ROLL },
      reject: { type: TEAM_ACTIONS.REJECT },
    };
  },
  computed: {
    animationName() {
      return 'slideleft';
    },
    shouldDisplayPoi() {
      if (
        this.poi &&
        this.poi.appearsOnlyOnEvent === false &&
        this.appConfig.platformType !== PLATFORM_TYPE.CITYPOLY
      ) {
        return true;
      }
      return false;
    },
    shouldDisplayCitypolyPoi() {
      if (this.poi && this.poi.appearsOnlyOnEvent === false && this.appConfig.platformType === PLATFORM_TYPE.CITYPOLY) {
        return true;
      }
      return false;
    },
  },
  methods: {
    closePOIPanel() {
      this.$emit('close');
    },
  },
};
</script>
<style scoped>
.slideleft-leave-active,
.slideleft-enter-active {
  transition: 0.2s ease-in;
}
.slideleft-enter {
  transform: translate(-100%, 0);
  position: absolute;
}
.slideleft-leave-to {
  transform: translate(-100%, 0);
  position: absolute;
}
.slideright-leave-active,
.slideright-enter-active {
  transition: 0.2s ease-in;
}
.slideright-enter {
  transform: translate(100%, 0);
  position: absolute;
}
.slideright-leave-to {
  transform: translate(100%, 0);
  position: absolute;
}
.notify--target {
  overflow: hidden;
}
</style>
