import { computed, ref } from 'vue';
import { useConfigStore } from '../pinia/configuration';

/**
 * POIManager is a composabble that takes a POI object and returns a set of computed properties
 * that can be used to display the POI in a UI, with the correct localization as obtained from the app Config store.
 * @param {*} poi
 * @returns
 */
export function usePOIManager(poi) {
  const appConfig = useConfigStore().appConfig;
  const currentPOI = ref(poi);
  const defaultPicture = `${appConfig.imagesBaseUrl}/default.jpg`;
  const picture = computed(() => {
    if (!currentPOI.value) {
      return defaultPicture;
    }
    if (currentPOI.value.picture) {
      return `${appConfig.imagesBaseUrl}/${currentPOI.value.picture}`;
    }
    return defaultPicture
  });
  const pictures = computed(() => {
    const picturesList = [];
    if (!currentPOI.value) {
      return ['/img/default.jpg'];
    }
    if (!currentPOI.value.pictures || currentPOI.value.pictures.length === 0) {
      return ['/img/default.jpg'];
    }
    const sortedPictures = [...currentPOI.value.pictures].sort((a, b) => a.order - b.order);
    sortedPictures.forEach((p) => {
      picturesList.push(`${appConfig.imagesBaseUrl}/${currentPOI.value.siteId}/${p.filename}`);
    });
    return picturesList;
  });
  const title = computed(() => {
    if (!currentPOI.value) {
      return '';
    }
    return currentPOI.value.localizedTitle;
  });

  const description = computed(() => {
    if (!currentPOI.value) {
      return '';
    }
    return currentPOI.value.localizedDescription;
  });

  const distanceText = computed(() => {
    if (!currentPOI.value) {
      return '';
    }
    const d = Math.round(currentPOI.value.distance);
    if (d <= k.UNKNOWN_DISTANCE) {
      return '';
    }
    if (d > k.UNKNOWN_DISTANCE && d <= appConfig.baseCheckinDistance) {
      return 'Vous y êtes !';
    }
    if (d > appConfig.baseCheckinDistance && d <= 1000) {
      return `${Math.round(d / 100) * 100}m`;
    }

    return `${Math.round(d / 1000)} km`;
  })
  const poiTitle = computed (() =>  {
    if (!currentPOI.value) {
      return '';
    }
    const { lang } = appConfig;
    let title = '';
    Object.keys(currentPOI.value.locales).forEach((l) => {
      if (l.substring(0, 2) === lang) {
        title = currentPOI.value.locales[l].name;
      }
    });
    return title;
  });
  const poiDescription = computed(() => {
    if (!currentPOI.value) {
      return '';
    }
    const { lang } = appConfig;
    let desc = '';
    Object.keys(currentPOI.value.locales).forEach((l) => {
      if (l.substring(0, 2) === lang) {
        desc = currentPOI.value.locales[l].description;
      }
    });
    return desc;
});

const websiteLink = (websiteURL) => {
  if (!websiteURL || websiteURL === '') {
    return '';
  }
  return `<a href="${websiteURL}">${websiteURL}</a>`;
};

  return {
    picture,
    pictures,
    title,
    description,
    distanceText,
    poiTitle,
    poiDescription,
    websiteLink,
  };
};
