import axios from 'axios';
import axiosRetry from 'axios-retry';
axiosRetry(axios, { retries: 3 });


export const fetchTeamByToken = async (gameApiBaseURL, token, isCitypoly = false ) => {

  let url = `${gameApiBaseURL}/gameapi/v1/me`
  if (isCitypoly === true) {
    url = `${gameApiBaseURL}/modules/citypoly/me`;
  }
  const response = await axios.get(url, { headers: {'Authorization': `Bearer ${token}`}});
  return response.data;
};

export const createTeam = async (gameApiBaseURL, teamData) => {
  const response = await axios.post(`${gameApiBaseURL}/gameapi/v1/teams`, teamData);
  return response.data;
}

export const fetchPartyById = async (gameApiBaseURL, partyId) => {
  const response = await axios.get(`${gameApiBaseURL}/gameapi/v1/parties/${partyId}`);
  return response.data;
};

export const fetchDisabledPlaces = async (gameApiBaseURL, token) => {
  const response = await axios.get(`${gameApiBaseURL}/gameapi/v1/me/disabled-places`, { headers: {'Authorization': `Bearer ${token}`}});
  return response.data;
}
export const fetchTeamLogs = async (gameApiBaseURL, teamId, token) => {

  const response = await axios.get(`${gameApiBaseURL}/gameapi/v1/teams/${teamId}/logs`, { headers: {'Authorization': `Bearer ${token}`}});
  return response.data;
}

export const fetchPropertyTitles = async (gameApiBaseURL, token) => {
  const response = await axios.get(`${gameApiBaseURL}/modules/citypoly/property-titles`, { headers: {'Authorization': `Bearer ${token}`}});
  return response.data;
}

export const performCheckin = async (
  gameApiBaseURL,
  {
    name,
    teamId,
    partyId,
    poiId,
    longitude,
    latitude,
    points,

  }, token) => {

    const checkinData =  {
      name,
      partyId,
      teamId,
      poiId,
      longitude,
      latitude,
      points,
      type: 'checkin',
    };
  const response = await axios.post(`${gameApiBaseURL}/gameapi/v1/checkin`, checkinData, { headers: {'Authorization': `Bearer ${token}`}});
  return response.data;
}

export const sendWelcomeEmail = async (gameApiBaseURL, email, gameId) => {
  const response = await axios.post(`${gameApiBaseURL}/gameapi/v1/teams/email`, { email, partyId: gameId });
  return response.data;
}
