<template>
  <div class="notify__department__section">
    <h3 class="notify__department__title notify__heading">{{ title }}</h3>

    <div class="notify__entry">
      <iframe class="yt-frame"
        :width="dimensions.width"
        :height="dimensions.height"
        :src="embedURL"
        frameborder="0"
        allow="accelerometer; autoplay;
        clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>
      </iframe>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    shouldLoop: {
      type: Boolean,
      required: true,
    },
    shouldAutoplay: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    embedURL() {
      if (!this.url) {
        return null;
      }
      const vid = this.url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
      if (vid === null) {
        return null;
      }
      if (vid.length === 2) {
        const autoPlay = this.shouldAutoplay ? 1 : 0;
        const loop = this.shouldLoop ? 1 : 0;
        return `https://www.youtube-nocookie.com/embed/${vid[1]}?autoplay=${autoPlay}&loop=${loop}`;
      }
      return vid[1] || null;
    },
    dimensions() {
      if (window.innerWidth >= 768) {
        return { width: 426, height: 240 };
      }
      return { width: 330, height: 145 };
    },
  },
};
</script>
