<template>
  <router-view v-slot="{ Component }">
    <transition
      name="fadeout"
      mode="out-in"
    >
      <InitialLoader v-if="!loaded" />
      <component
        :is="Component"
        v-else
      />
    </transition>
  </router-view>
</template>

<script setup>

import Cookies from 'js-cookie';
import { computed, ref, onMounted} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { useDestinationStore } from './pinia/destination';
import { useConfigStore } from './pinia/configuration';
import { PLATFORM_TYPE } from './constants';
import InitialLoader from './components/map/InitialLoader.vue';
import { useCategoriesStore } from './pinia/categories';
import { useGameStore } from './pinia/game';
import { usePoiStore } from './pinia/poi';
import { useWebSocketStore } from './pinia/websocket';
const loaded  = ref(false);
const isFetchingData = ref(false);

const  i18n  = useI18n();
const router = useRouter();
const route = useRoute();
const cfgStore = useConfigStore();
const gameStore = useGameStore();
const destinationStore = useDestinationStore();
const categoriesStore = useCategoriesStore();
const poisStore = usePoiStore();
const wsStore = useWebSocketStore();
const destination = ref(null);

const teamAuthenticated = computed(() => gameStore.currentTeam !== null);
const currentTeam = computed(() => gameStore.currentTeam);
const currentParty = computed(() => gameStore.currentParty);



onMounted(async () => {
  try {
    destination.value = await destinationStore.fetchDestination();
    if (destination.value.platformType === PLATFORM_TYPE.CITYPOLY) {
      await router.replace({ name: 'citypolyWelcome' });
    }
    reloadLang();
    await fetchData();
    loaded.value = true;
  } catch (error) {
    console.log('Error initializing app', error);
  }
});

/**
 * Reload the language from the cookie or the party  if needed.
 */
const reloadLang = () => {
  let lang = Cookies.get('party_lang');
      if (!lang) {
        if (currentParty.value && currentParty.value.lang) {
          lang = currentParty.value.lang;
        } else {
        lang = navigator.language || navigator.userLanguage;
      }
    }
      lang = lang.substring(0, 2);
      cfgStore.appConfig.lang = lang;
      i18n.locale.value = lang;
};

/**
 * Fetch the data needed for the app to start.
 * The same method exists in views/Map.vue but this initial call is needed for a proper initialization of the app.
 */
const fetchData = async() => {
  // avoid fetching data multiple times in parallel
  if (!isFetchingData.value) {
    isFetchingData.value = true;

    // select the correct team fetch function depending on the platform type
      await Promise.all([
        categoriesStore.fetchCategories(destination.value.id, 'fr'),
        poisStore.getPois(),
        gameStore.loadTeamContext(),
        wsStore.connect(cfgStore.appConfig.websocketUrl),
      ])
      if (
        teamAuthenticated.value === false &&
        cfgStore.appConfig.restrictContentToPlayers === true &&
        cfgStore.appConfig.enableRegistration === true &&
        route.name !== 'Register'
        ) {
        isFetchingData.value = false;
        router.push({ name: 'Register' });
        return;
        }
      if (teamAuthenticated.value === false) {
        isFetchingData.value = false;
        return;
      }
    isFetchingData.value = false;
    loaded.value = true;
  }
}
</script>
<style>

.fadeout-enter-active,
.fadeout-leave-active {
  transition: opacity 0.6s;
}
.fadeout-enter-from,
.fadeout-leave-to {
  opacity: 0;
}
</style>
