<template>
  <div class="card-primary card-primary--alt">
    <figure class="card__image">
      <cloud-image
        v-if="picture"
        :base-width="100"
        :ratio="0.85"
        :use-pixel-density="true"
        :picture-path="picture"
        :lazy="false"
      />
    </figure>
    <div class="card__entry">
      <h3>{{ title }}</h3>
      <p>
        {{ desc }}
      </p>
      <span
        v-if="distance > -1 && distance < checkinDistance"
        class="card__alert"
      >
        Vous y êtes !
      </span>
      <span
        v-else-if="distance > checkinDistance"
        class="card__faded"
      >
        {{ distanceTxt }}
      </span>
      <a
        class="btn btn--wide btn--unshadow"
        :href="`/#/pois/${id}`"
      >
        {{ $t('components.CardCity.button_more_info') }}
      </a>
    </div>
    <span
      v-if="enableCheckin === true"
      class="label label--red label--squared card__label"
    >
      {{ points }}
      <small>pts</small>
    </span>
  </div>
</template>

<script>
import filters from '../../filters';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    picture: {
      type: String,
      required: true,
    },
    distance: {
      type: Number,
      required: true,
    },
    points: {
      type: Number,
      required: true,
    },
    displayPoints: {
      type: Boolean,
      required: false,
      default: true,
    },
    checkinDistance: {
      type: Number,
      required: false,
      default: 50,
    },
    enableCheckin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    desc() {
      return filters.TruncateText(filters.StripHTML(this.description));
    },
    distanceTxt() {
      return filters.DistanceText(this.distance);
    },
  },
};
</script>
