<template>
  <div
    v-if="clues.length !== 0"
    class="clues-container"
  >
    <h3 class="clue-header">
      {{ $t("components.CluesQuestion.title") }}
    </h3>
    <div class="clues-btn">
      <button
        v-for="(clue, idx) in clues"
        :key="idx"
        class="clue"
        :class="bgColorClue(clue.unlocked)"
        type="button"
        @click="alertClue(clue.unlocked, clue.clue)"
      >
        {{ $t("components.CluesQuestion.clue") + " " + (idx + 1) }}
        <lock v-if="clue.unlocked === false" />
        <lock-open-variant v-if="clue.unlocked === true" />
      </button>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2';
import Lock from 'vue-material-design-icons/Lock.vue';
import LockOpenVariant from 'vue-material-design-icons/LockOpenVariant.vue';
import { getClues, buyClue } from '../../api/clues';
import toast from '../../tools/toastAlert';
import { useConfigStore } from '../../pinia/configuration';
import { useGameStore } from '../../pinia/game';
export default {
  components: {
    Lock,
    LockOpenVariant,
  },
  props: {
    questionId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const appConfig = useConfigStore().appConfig;
    const gameStore = useGameStore();
    return { appConfig, gameStore };
  },
  data() {
    return {
      clues: [],
      displayClueAlert: false,
      FORBIDEN_STATUS: 'Forbidden',
    };
  },
  computed: {
    teamId() { return this.gameStore.currentTeam.id},
    token() { return this.gameStore.currentToken},
  },
  mounted() {
    this.getClues();
  },
  methods: {
    async getClues() {
      this.clues = [];
      try {
        const res = await getClues(
          this.appConfig.gameApiBaseUrl,
          this.teamId,
          this.questionId,
          this.token,
        );
        res.sort((a, b) => a.clue.cost - b.clue.cost);
        this.clues = res;
      } catch (error) {
        console.error('Impossible to get clues', error);
        toast.fire({ icon: 'error', title: this.$t('components.CluesQuestion.errorGetClues') });
      }
    },
    alertClue(clueStatus, clue) {
      if (clueStatus === true) {
        Swal.fire({
          title: this.$t('components.CluesQuestion.clueTitle'),
          text: `${clue.content}`,
        });
      } else {
        Swal.fire({
          title: this.$t('components.CluesQuestion.buyClueTitle'),
          text: this.$t('components.CluesQuestion.labelCost', { cost: clue.cost }),
          showDenyButton: true,
          confirmButtonText: this.$t('components.CluesQuestion.accept'),
          denyButtonText: this.$t('components.CluesQuestion.decline'),
        }).then((result) => {
          if (result.isConfirmed) {
            this.buyClue(clue);
          }
        });
      }
    },
    async buyClue(clue) {
      const requestedClue = {
        teamId: this.teamId,
        clueId: clue.id,
      };
      try {
        await buyClue(this.appConfig.gameApiBaseUrl, requestedClue, this.token);
        Swal.fire({
          title: this.$t('components.CluesQuestion.clueTitle'),
          text: `${clue.content}`,
        }).then(() => {
          this.getClues();
        });
      } catch (error) {
        if (error.message.includes(this.FORBIDEN_STATUS)) {
          Swal.fire({
            title: this.$t('components.CluesQuestion.labelBlockedClue'),
            text: this.$t('components.CluesQuestion.labelInsufficientFunds'),
          });
        } else {
          console.error('Error when buying an clue', error);
          toast.fire({
            icon: 'error',
            title: this.$t('components.CluesQuestion.errorBuyingClue'),
          });
        }
      }
    },
    bgColorClue(clueStatus) {
      if (clueStatus === true) {
        return 'clue-btn-unlocked';
      }
      return 'clue-btn-locked';
    },
  },
};
</script>
<style lang="scss" scoped>
.clues-container {
  margin: 1rem auto;
  padding-bottom: 0.5rem;
  background-color: #f4f4f4;
  border: solid 1px #f2f2f2;
  border-radius: 5px;
}
.clue-header {
  border-radius: 5px;
  margin: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 0.5rem;
  text-align: center;
  color: #da004b;
}
.clues-btn {
  display: flex;
  justify-content: space-evenly;
}
.clue {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 28%;
  margin: 0.4rem;
  padding: 0.4rem;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  & span {
    // pour aligner l'icon avec le text
    height: .7rem;
  }
}
.clue-btn-unlocked {
  background-color: #8f96a3;
  color: white;
}
.clue-btn-locked {
  background-color: #040404;
  color: white;
}
</style>
