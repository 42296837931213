<template>
  <div>
    <a
      href="#"
      class="notify__button is-active js-notify-toggler"
      :class="isDisabled"
      @click.prevent="$emit('clicked', { action: btnContent.action})"
    >
      {{ btnContent.title }}
      <span v-if="btnContent.subtitle"> <br>{{ btnContent.subtitle }}</span>
    </a>
  </div>
</template>

<script setup>
import {computed} from 'vue';
// import { checkinDone } from '../../tools/checkin';
import {useConfigStore} from '../../pinia/configuration';
import { useI18n } from 'vue-i18n';
import { useGameStore } from '../../pinia/game';
import { usePoiStore } from '../../pinia/poi';
import * as k from '../../constants';
import { UseCheckinControl } from '../../composables/CheckinControl';

const appConfig = useConfigStore().appConfig;
const gameStore = useGameStore();
const poiStore = usePoiStore();
const { t } = useI18n();
const props = defineProps({
  poi: {
    type: Object,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
  surveyCount: {
    type: Number,
    required: true,
  },
});
const { checkinDone, canCheckin, isWithinCheckinDistance } = UseCheckinControl(props.poi);

const locationKnown = computed(() =>  poiStore.locationStatus === k.LOCATION_STATUS.OK);
const authenticated = computed(() => gameStore.currentTeam !== null);
const currentTeam = computed(() => gameStore.currentTeam);
const teamIsBlocked = computed(() => gameStore.teamIsBlocked);
const isGPSAllowed = computed(() => appConfig.enableGeolocation === true);
const isCheckinAllowed = computed(() => appConfig.enableCheckin === true);
const isDisabled = computed(() => {
  if (props.loading === true || teamIsBlocked.value === true) {
    return 'disabled';
  }
  return '';
});
const btnContent = computed(() => {
      const content = {
        title: t('components.PanelContainer.button.next_place'),
        subtitle: null,
        action: 'closeCard',
      };
      if (props.loading === true) {
        content.title = t('components.PanelContainer.button.loading');
        content.subtitle = null;
        content.action = 'noop';
        return content;
      }
      // guidage GPS, personne hors du lieu
      if (!isWithinCheckinDistance.value) {
        if (isGPSAllowed.value) {
          content.title = t('components.PanelContainer.button.start_gps');
          content.subtitle = null;
          content.action = 'startGPS';
          return content;
        }
        content.title = t('components.PanelContainer.button.no_gps');
        content.subtitle = null;
        content.action = 'closeCard';
        return content;
      }
      // Posiition GPS inconnue. Message d'erreur sur le bouton
      if (!locationKnown.value) {
        content.title = t('components.PanelContainer.button.user_location_unknown.title');
        content.subtitle = t('components.PanelContainer.button.user_location_unknown.subtitle');
        return content;
      }
      // dans la distance de checkin
      // Checkin non autorisé;
      if (!isCheckinAllowed.value) {
        content.title = t('components.PanelContainer.button.checkin_disabled');
        content.subtitle = null;
        content.action = 'noop';
        return content;
      }
      // checkin autorisé

      // cas ou l'equipe n'est pas connectée
      if (currentTeam.value === null && !authenticated.value) {
        if (appConfig.enableRegistration === true
      && appConfig.enableLogin === false) {
          content.title = t('components.PanelContainer.button.createAccount');
          content.subtitle = null;
          content.action = 'register';
          return content;
        }
        if (!isCheckinAllowed.value) {
          content.title = t('components.PanelContainer.button.checkin_disabled');
          content.subtitle = null;
          content.action = 'noop';
          return content;
        }
        if (appConfig.enableRegistration === false
      && appConfig.enableLogin === true) {
          content.title = t('components.PanelContainer.button.loginAccount');
          content.subtitle = null;
          content.action = 'login';
          return content;
        }
        if (appConfig.enableRegistration === false
      && appConfig.enableLogin === false) {
          content.title = t('components.PanelContainer.button.noLoginAndNoCreation');
          content.subtitle = null;
          content.action = 'noop';
          return content;
        }
        content.title = t('components.PanelContainer.button.team_checkin_authenticate.title');
        content.subtitle = t('components.PanelContainer.button.team_checkin_authenticate.subtitle');
        content.action = 'login';
        return content;
      }
      // l'equipe est bien connectée!

      // cas des checkindone non faits
      if (!checkinDone.value && props.poi.checkinType === 'gps') {
        content.title = t('components.PanelContainer.button.checkin');
        content.subtitle = null;
        content.action = 'checkin';
        return content;
      }
      if (props.poi.checkinType === 'qrcode' && !checkinDone.value) {
        content.title = t('components.PanelContainer.button.isQrCode.title');
        content.subtitle = null;
        content.action = 'scan';
        return content;
      }
      // le checkinDone est fait
      // quizz non répondu = repondre aux quizzs.
      if (props.surveyCount > 0) {
        content.title = t('components.PanelContainer.button.answer_survey', props.surveyCount);
        content.subtitle = null;
        content.action = 'quiz';
        return content;
      }
      // on return content: si aucune des conditions ne passe, alors il affiche le contenu de base:
      // RDV au lieu suivant

      return content;
});
</script>
