<template>
  <btn-round
    :classes="classes"
    icon="/images/icons/ico-gift-plain.svg"
    :width="20"
    :height="19"
  >
    <template #btn-extension>
      <div class="btn__extension">
        <span v-if="diff !== 0">{{ operator }}{{ diff }} <sup>PTS</sup></span>
        <span v-else> {{ balance }} <sup>PTS</sup> </span>
      </div><span
        v-if="balance >=0 && diff !== 0"
        class="btn__entry"
      >
        <span>Mon Solde : </span>
        <strong>{{ balance }} PTS</strong>
      </span>
    </template>
  </btn-round>
</template>

<script>

import BtnRound from './BtnRound.vue';

export default {
  components: { BtnRound },
  props: {
    balance: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      previousBalance: 0,
      operator: '+',
      diff: 0,
      shouldDisplayBalance: false,
      initialLoading: true,
    };
  },
  computed: {
    classes() {
      if (this.shouldDisplayBalance === true) {
        return 'btn-round--secondary btn-round-extendable is-animating';
      }
      return 'btn-round--secondary btn-round-extendable';
    },
  },
  watch: {
    balance() {
      this.diff = this.balance - this.previousBalance;
      if (Number.isNaN(this.diff)) {
        this.diff = 0;
        this.previousBalance = this.balance;
        return;
      }
      if (this.diff < 0) {
        this.operator = '-';
      } else {
        this.operator = '+';
      }

      this.previousBalance = this.balance;
      if (this.initialLoading) {
        this.initialLoading = false;
        return;
      }
      if (this.diff === 0) {
        return;
      }
      this.shouldDisplayBalance = true;
      setTimeout(() => { this.shouldDisplayBalance = false; this.diff = 0; }, 3000);
    },
  },

};
</script>
