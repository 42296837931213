<template>
  <div
    class="notify"
    :class="expanded ? ' is-expanded': ''"
  >
    <div
      class="notify__header"
      @click="toggle"
    >
      <h3
        class="notify__title"
        v-html="localizedDestinationName"
      />
      <BtnClosePanel v-if="expanded" />
    </div>
    <div
      v-if="shouldDisplayContent"
      class="notify__content"
    >
      <div class="notify__section notify__section--dark">
        <div class="tabs js-tabs">
          <div class="tabs__head">
            <nav class="tabs__nav">
              <ul>
                <li
                  class="is-active"
                >
                  <a
                    href="#tab-1"
                    @click.prevent="activeTab = 'PLACES_TAB'"
                  >
                    <img
                      :src="`/themes/${theme}/images/icons/ico-marker.svg`"
                      width="32"
                      height="30"
                      alt="POIs icon"
                    >
                    {{ $t('components.CardCity.button_tab_places') }}
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          <div
            id="tab-body-container__places"
            class="tabs__body"
          >
            <div
              id="tab-1"
              class="tab is-active"
            >
              <div
                v-for="(p, idx) in pois"
                :key="p.id"
                v-observe-visibility.once="(isVisible, entry) => visChanged(isVisible, entry, idx)"
                class="card-location-container"
              >
                <card-location
                  :id="p.uuid"
                  :title="poiTitle(p, lang)"
                  :description="poiDescription(p, lang)"
                  :picture="poiPictures(p) ? poiPictures(p)[0] : '/img/default.jpg'"
                  :points="p.points"
                  :distance="p.distance ? p.distance : -1"
                  :display-points="enableCheckin === true"
                  :checkin-distance="baseCheckinDistance"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import CardLocation from './PanelModuleLocation.vue';
import BtnClosePanel from './BtnClosePanel.vue';
import { poiTitle, poiDescription, poiPictures } from '../../translationTools/poi';
import { useConfigStore } from '../../pinia/configuration';
import { useDestinationStore } from '../../pinia/destination';
import { usePoiStore } from '../../pinia/poi';
import { useUIStore } from '../../pinia/uistate';
const PLACES_TAB = 'PLACES_TAB';

const MAX_DISPLAYED_POIS = 39;

const uiStore = useUIStore();
const cfgStore = useConfigStore();
const poiStore = usePoiStore();
const props = defineProps({
  shouldDisplayContent: {
    type: Boolean,
    required: true,
  },
});
const destinationStore = useDestinationStore();
const activeTab = ref(PLACES_TAB);
const loadedPois = ref(1);

const theme = computed(() => cfgStore.appConfig.cssTheme);
const destination = computed(() => destinationStore.currentDestination);
const expanded = computed(() => uiStore.cityPanelOpen);
const lang = computed(() => cfgStore.appConfig.lang);
const enableMainPoiList = computed(() => cfgStore.appConfig.enableMainPoiList);

const poisList = computed(() => poiStore.activePois);

const localizedDestinationName = computed(() => {
  const lang = cfgStore.appConfig.lang;

  const locale = destination.value.locales.find((l) => l.locale.substr(0,2) === lang);
  if (locale) {
    return locale.name;
  }
  return destination.value.name;
})

const pois = computed(() => {
  const { length } = poisList.value;
  let displayedPois = loadedPois.value * MAX_DISPLAYED_POIS;
  if (displayedPois >= length) {
    displayedPois = length;
  }
  return poisList.value.slice(0, displayedPois);
});
const enableCheckin = computed(() => cfgStore.appConfig.enableCheckin);
const baseCheckinDistance = computed(() => cfgStore.appConfig.baseCheckinDistance);

const toggle = () => {
  if (expanded.value === true) {
    loadedPois.value = 1;
    uiStore.cityPanelOpen = false;
  } else if (enableMainPoiList.value === false) {
    uiStore.cityPanelOpen = false;
  } else {
    uiStore.cityPanelOpen = true;
  }
};

const visChanged = (isVisible, entry, index) => {
  if (isVisible === true && index === (loadedPois.value * MAX_DISPLAYED_POIS) - 1) {
    loadedPois.value += 1;
  }
};

</script>
<style lang="scss" scoped>

.notify__header {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
}
.notify__title {
    flex-grow: 4;
    cursor: pointer;
}
</style>
