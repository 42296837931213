<template>
  <div
    class="overlay"
    :class="expanded ? 'is-expanded' : ''"
  >
    <div class="overlay__header">
      <h3 class="overlay__title">
        {{ $t('components.MenuCategories.title') }}
      </h3>
      <a
        href="#"
        class="overlay__close"
        @click="dismiss"
      >
        <img
          src="/images/icons/ico-close-round.svg"
          width="48"
          height="48"
          alt=""
        >
      </a>
    </div>

    <div class="overlay__content">
      <category-container
        :categories="categories"
        :active-categories="selectedCats"
        @save="saveCategories"
      />
    </div>
    <div class="overlay__footer">
      <button
        type="submit"
        class="btn btn--red btn--tertiary btn--unshadow"
        @click.prevent="save"
      >
        {{ $t('components.MenuCategories.save') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCategoriesStore } from '../../pinia/categories';
import CategoryContainer from './CategoryContainer.vue';

const props = defineProps({
  expanded: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['close']);
const { t } = useI18n();

const categoriesStore = useCategoriesStore();
const selectedCats = ref(categoriesStore.activeCategories);

const categories = computed(() => categoriesStore.allCategories);

const dismiss = () => {
  selectedCats.value = [];
  emit('close');
};

const saveCategories = (cats) => {
  selectedCats.value = cats;
};

const save = () => {
 categoriesStore.activeCategories = selectedCats.value;
  emit('close');
};
</script>

<style scoped>
hr {
  width: 80%;
  color: #E9EDF4;
  margin: 30px auto;
}
</style>
