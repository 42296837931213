<template>
  <div
    class="card-property-title"
    :class="{ 'is-mortgaged': props.poi.isMortgaged }"
  >
    <div class="card-header">
      <h3 class="card-title">
        {{ poiTitle }}
      </h3>
    </div>
    <div
      v-if="cardPicture"
      class="card-picture"
    >
      <img
        :src="cardPicture"
        alt="poi image"
      >
    </div>
    <div class="card-content">
      <p>{{ $t(pfx + "price") + props.poi.price + " Cityflouz" }}</p>
    </div>
    <div
      v-if="props.poi.isMortgaged"
      class="mortgaged-overlay"
    >
      <span class="mortgaged-text">{{ $t(pfx + "isMortgaged") }}</span>
    </div>
    <div class="card-property-footer">
      <button
        v-if="props.poi.isMortgaged && removeMortgageIsDisable"
        class="btn clickable"
        :disable="removeMortgageIsDisable"
        @click="emit('removeMortgage', props.poi.uuid)"
      >
        {{ $t(pfx + 'removeMortgage') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { useConfigStore } from '../../pinia/configuration';

const appConfig = useConfigStore().appConfig;
const pfx = 'components.citypoly.CardProperty.';
const emit = defineEmits(['removeMortgage']);

const props = defineProps({
  poi: {
    type: Object,
    required: true,
  },
  currentTeamScore: {
    type: Number,
    required: true,
  },
});

const cardPicture = computed(() => {
  if (props.poi.pictures.length > 0) {
    return `${appConfig.imagesBaseUrl}/${props.poi.siteId}/${props.poi.pictures[0].filename}`;
  }
  return null;
});
const poiTitle = computed(() => {
  const filteredlocale = Object.assign(
    {},
    ...Object.values(props.poi.locales).filter(
      (value) => value.locale.slice(0, 2) === appConfig.lang
    )
  );
  return filteredlocale.name;
});

const removeMortgageIsDisable = computed(() => {
  const mortgageValue = props.poi.points / 2;
  if (props.currentTeamScore >= mortgageValue * 1.1) {
    return true;
  }
  return false;
});
</script>

<style lang="scss" scoped>
.card-property-title {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 1rem;
  background-color: #fff;
  box-shadow: 9px 14px 32px -14px rgba(56, 56, 56, 1);
  position: relative;

  &.is-mortgaged {
    background-color: rgba(200, 200, 200, 0.5);
  }
}

.card-header {
  background-color: #52bcf1;
  border-radius: 8px 8px 0 0;
  padding: 0.4rem;
}

.card-title {
  text-align: center;
  color: #fff;
  font-weight: bold;
}

.card-picture {
  img {
    object-fit: contain;
    width: 100%;
  }
}

.card-content {
  color: #000;
  padding: 1rem;
  font-size: 1rem;
  border-radius: 0 0 8px 8px;
}

.mortgaged-overlay {
  position: absolute;
  border-radius: 8px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 0, 0, 0.7);
}

.mortgaged-text {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.card-property-footer {
  margin-top: 1rem;
  display: flex;
  justify-content: end;
  padding: 0.4rem;
}
</style>
