<template>
  <div class="chat-item">
    <div class="chat-bubble-title">
      <div class="chat-bubble-header">
        <span class="chat-bubble-author">{{ props.message.fromUserName }}</span>
        <span class="chat-bubble-date">{{ formatDate(props.message.sentAt) }}</span>
      </div>
    </div>
    <div class="chat-bubble-body">
      <p>{{ props.message.content }}</p>
    </div>
  </div>
</template>
  
  <script setup>
  import { computed } from 'vue';
import formatDate from '../../tools/formatDate';
  
  const props = defineProps({
    message: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  });
  
  </script>
  
  <style lang="scss" scoped>
  .chat-item {
    padding-bottom: 1rem;
    margin: 1rem;
    width: 50vw;
  }
  
  .chat-bubble {
    max-width: 60%;
    padding: 1rem;
    border-radius: 10px;
    background-color: #f0f0f0;
    color: #333;
  }
  
  .chat-bubble-admin {
    background-color: #9c9c9c;
    color: #fff;
  }
  
  .chat-bubble-user {
    background-color: #e0e0e0;
  }
  
  .chat-bubble-title {
    font-weight: bold;
    margin-bottom: 0.25rem;
  }
  
  .chat-bubble-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
  }
  
  .chat-bubble-author {
    font-weight: 600;
  }
  
  .chat-bubble-date {
    font-size: 0.75rem;
    color: #999;
  }
  
  .chat-bubble-body {
    font-size: 1rem;
    margin-top: 0.25rem;
  }
  </style>
  