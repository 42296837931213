<template>
  <div
    v-if="show"
    class="lightbox-overlay"
  >
    <responsive-image
      ref="pic"
      :picture-path="picturePath"
      :base-width="imgWidth"
      :ratio="1.78"
      mode="fit"
    />
    <picture-btn
      icon="close"
      :text="$t('common.buttons.picture_close')"
      @btnpressed="$emit('hide')"
    />
    <picture-btn
      v-if="hasPrevious"
      icon="left"
      text="précédente"
      @btnpressed="previous()"
    />
    <picture-btn
      v-if="hasNext"
      icon="right"
      text="suivante"
      @btnpressed="next()"
    />
    <credits-caption
      v-if="currentPicture && currentPicture.author && currentPicture.author.length > 0"
      :text="currentPicture.author"
    />
  </div>
</template>

<script>
import CreditsCaption from './CreditsCaption.vue';
import ResponsiveImage from './ResponsiveImage.vue';
import PictureBtn from './PictureBtn.vue';

export default {
  components: {
    ResponsiveImage,
    PictureBtn,
    CreditsCaption,
  },
  props: {
    pictures: {
      type: Array,
      required: false,
      default: () => [],
    },
    initialIndex: {
      type: Number,
      required: false,
      default: 0,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      width: 0,
      currentIndex: 0,
    };
  },

  computed: {

    imgWidth() {
      return this.width;
    },
    hasNext() {
      return this.currentIndex < (this.pictures.length - 1);
    },
    hasPrevious() {
      return this.currentIndex > 0;
    },
    picturePath() {
      const p = this.pictures[this.currentIndex];
      if (p && p.url) {
        return p.url;
      }
      return 'default.png';
    },
    currentPicture() {
      return this.pictures[this.currentIndex];
    },
  },
  watch: {
    initialIndex() {
      this.currentIndex = this.initialIndex;
    },
  },

  created() {
    this.computeWidth();
    window.addEventListener('resize', this.computeWidth);
    window.addEventListener('orientationchange', this.computeWidth);
    if (!this.pictures || !this.initialIndex || this.initialIndex >= this.pictures.length) {
      this.currentIndex = 0;
    }
    this.currentIndex = this.initialIndex;
  },
  mounted() {
    if (!this.pictures || !this.initialIndex || this.initialIndex >= this.pictures.length) {
      this.currentIndex = 0;
    }
    this.currentIndex = this.initialIndex;
  },
  unmounted() {
    window.removeEventListener('resize', this.computeWidth);
    window.removeEventListener('orientationchange', this.computeWidth);
  },
  methods: {
    reloadImage() {
      this.$refs.pic.baseWidth = this.width;
    },
    computeWidth() {
      const width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

      this.width = Math.ceil(width * 0.8);
    },
    next() {
      const mx = this.pictures.length - 1;
      if (this.currentIndex < mx) {
        this.currentIndex += 1;
      }
    },
    previous() {
      if (this.currentIndex > 0) {
        this.currentIndex -= 1;
      }
    },
  },
};
</script>

<style scoped>

.lightbox-overlay {
  position: absolute;
  display: block;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.66);
  z-index: 500;
  cursor: pointer;
}

.lightbox-overlay > .lightbox-close {
  z-index: 51;
}
.lightbox-overlay > img {
  position: absolute;
  top: 50%;
  transform: translate(-50%,-50%);
  left: 50%;

  text-align: center;
  z-index: 51;
  padding: 10px;
}
</style>
