import http from './fetch';

const getClues = async (gameApiBaseURL, teamId, questionId, token) => {
  const url = `${gameApiBaseURL}/gameapi/v1/clues/for-team/${teamId}?question_id=${questionId}`;
  const res = await http.getJSON(url, {}, token);
  return res;
};

const buyClue = async (gameApiBaseURL, requestedClue, token) => {
  const url = `${gameApiBaseURL}/gameapi/v1/clues/buy`;
  const res = await http.postJSON(url, {}, requestedClue, token);
  return res;
};

export { getClues, buyClue };
