const MAX_TRUNCATE_CHARCACTERS_COUNT = 150;
const KM_PER_HOUR = 4;

const DistanceText = (distance) => {
  if (distance < 1000) {
    return '1km';
  }
  return `${Math.round(distance / 1000)} km`;
};

const DurationText = (distance) => {
  const duration = distance / 1000 / KM_PER_HOUR;
  if (duration <= 0.25) {
    return '15 min';
  }
  if (duration > 0.25 && duration <= 0.50) {
    return '30 min';
  }
  if (duration > 0.50 && duration <= 0.75) {
    return '45 min';
  }
  if (duration > 0.75 && duration <= 1) {
    return '1h';
  }
  return `${Math.round(duration)}h`;
};

const StripHTML = (value) => {
  const div = document.createElement('div');
  div.innerHTML = value;
  return div.textContent || div.innerText || '';
};

const TruncateText = (value) => (value.length <= MAX_TRUNCATE_CHARCACTERS_COUNT
  ? value : `${value.substring(0, MAX_TRUNCATE_CHARCACTERS_COUNT)}...`);

export default {
  DistanceText,
  TruncateText,
  DurationText,
  StripHTML,
};
