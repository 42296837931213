<template>
  <form>
    <div class="quizz_question_title">
      {{ props.question.question }}
    </div>
    <div v-if="question.type === SIMPLE_QUESTION_TYPE.TEXT">
      <div class="propositions">
        <label
          v-for="p in question.propositions"
          :key="p.id"
          class="quizz"
        >
          <input
            :id="`prop_${p.id}`"
            :type="question.allowMultipleChoices ? 'checkbox' : 'radio'"
            :value="p.id"
            :name="`poll_${question.id}`"
            @click="onSelectProposition(p.id)"
          >
          <span class="quizz__content">
            <span class="poll__title">{{ p.title }}</span>
          </span>
        </label>
      </div>
    </div>
    <div v-if="question.type === SIMPLE_QUESTION_TYPE.IMAGE">
      <PanelModuleImage :image-src="question.url" />
      <div class="propositions">
        <label
          v-for="p in question.propositions"
          :key="p.id"
          class="quizz"
        >
          <input
            :id="`prop_${p.id}`"
            :type="question.allowMultipleChoices ? 'checkbox' : 'radio'"
            :value="p.id"
            :name="`poll_${question.id}`"
            @click="onSelectProposition(p.id)"
          >
          <span class="quizz__content">
            <span class="poll__title">{{ p.title }}</span>
          </span>
        </label>
      </div>
    </div>
    <div v-if="question.type === SIMPLE_QUESTION_TYPE.VIDEO">
      <PanelModuleVideo
        title=""
        :url="question.fileUrl"
        :should-loop="false"
        :should-autoplay="false"
      />
      <div class="propositions">
        <label
          v-for="p in question.propositions"
          :key="p.id"
          class="quizz"
        >
          <input
            :id="`prop_${p.id}`"
            :type="question.allowMultipleChoices ? 'checkbox' : 'radio'"
            :value="p.id"
            :name="`poll_${question.id}`"
            @click="onSelectProposition(p.id)"
          >
          <span class="quizz__content">
            <span class="poll__title">{{ p.title }}</span>
          </span>
        </label>
      </div>
    </div>
    <div v-if="question.type === SIMPLE_QUESTION_TYPE.AUDIO">
      <figure>
        <audio
          controls
          :src="question.fileUrl"
        />
      </figure>
      <div class="propositions">
        <label
          v-for="p in props.question.propositions"
          :key="p.id"
          class="quizz"
        >
          <input
            :id="`prop_${p.id}`"
            :type="question.allowMultipleChoices ? 'checkbox' : 'radio'"
            :value="p.id"
            :name="`poll_${question.id}`"
            @click="onSelectProposition(p.id)"
          >
          <span class="quizz__content">
            <span class="poll__title">{{ p.title }}</span>
          </span>
        </label>
      </div>
    </div>
    <div
      v-if="question.type === SIMPLE_QUESTION_TYPE.PHOTO"
      class="question-photo"
    >
      <BaseImageInput v-model="pictureFile" />
    </div>
    <div class="quizz__answer__container">
      <ButtonLoading
        :loading="loading"
        :disabled="sendBtnIsDisabled"
        :styled="false"
        class="btn btn--unshadow btn--secondary"
        @click="sendAndswer"
      >
        {{ $t(pfx + "sendAnswer") }}
      </ButtonLoading>
    </div>
  </form>
</template>

<script setup>
import { computed, ref } from 'vue';
import ButtonLoading from '../ButtonLoading.vue';
import PanelModuleImage from '../map/PanelModuleImage.vue';
import PanelModuleVideo from '../map/PanelModuleVideo.vue';
import BaseImageInput from '../BaseImageInput.vue';
import { SIMPLE_QUESTION_TYPE } from '../../constants';

const pfx = 'components.citypoly.SimpleQuestionForm.';
const emit = defineEmits(['sendAnswer']);

const props = defineProps({
  question: {
    type: Object,
    required: true,
  },
});

const loading = ref(false);
const selectedPropositionIds = ref([]);
const pictureFile = ref(null);

const sendBtnIsDisabled = computed(() => props.question.type === SIMPLE_QUESTION_TYPE.PHOTO && !pictureFile.value)

const onSelectProposition = (propositionId) => {
  const isSelected = selectedPropositionIds.value.includes(propositionId);
  if (props.question.allowMultipleChoices) {
    if (isSelected) {
      selectedPropositionIds.value = selectedPropositionIds.value.filter(
        (p) => p !== propositionId
      );
    } else {
      selectedPropositionIds.value.push(propositionId);
    }
  } else {
    if (!isSelected) {
      selectedPropositionIds.value = [propositionId];
    }
  }
};

const sendAndswer = () => {
  const answer = {
    questionId: props.question.id,
    propositionIds: selectedPropositionIds.value,
  };
  emit('sendAnswer', answer, pictureFile.value);
};
</script>
<style lang="scss" scoped>
.quizz_question_title {
  padding-top: 16px;
  padding-bottom: 8px;
  font-weight: bold;
}

.question-photo {
  display: flex;
  justify-content: center;
}
.propositions {
  padding: 0 1rem;
}
.quizz_question_title {
  margin-left: 0.4rem;
}
</style>
