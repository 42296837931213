<template>
  <li
    class="category-list-item"
    :class="active === true ? 'active' : ''"
  >
    <div @click="toggle">
      <p>
        <img
          :src="iconPath"
          :alt="`${name}`"
          width="48"
          height="48"
        >{{ name }}
      </p>
    </div>
    <div class="category-list-item-tags">
      <div
        v-if="category.tags.length > 0"
        class="tags"
        :class="active ? 'is-expanded' : ''"
      >
        <category-tag
          v-for="t in category.tags"
          :key="t.id"
          :is-active="tagIsActive(t)"
          :tag="t"
          @enable="enableTag"
          @disable="disableTag"
        />
      </div>
    </div>
  </li>
</template>

<script setup>
import { computed } from 'vue';
import { useConfigStore } from '../../pinia/configuration';
import CategoryTag from './CategoryTag.vue';

const cfg = useConfigStore().appConfig;

const emit = defineEmits(['enable', 'disable']);
const props = defineProps({
  category: {
    type: Object,
    required: true,
  },
  active: {
    type: Boolean,
    required: true,
  },
  activeTags: {
    type: Array,
    required: false,
    default: () => [],
  },
});

const name = computed(() => {
  if (props.category.locales && props.category.locales.length > 0) {
    return props.category.locales[0].title;
  }
  return props.category.name;
});

const iconPath = computed(() => {
  return `${cfg.cmsApiBaseUrl}/api/v3/sites/${props.category.destinationId}/icons/${props.category.icon.toLowerCase()}.svg`;
});

const toggle = () => {
  if (props.active === false) {
    emit('enable', { id: props.category.id, tags: props.activeTags });
  } else {
    emit('disable', props.category.id);
  }
};

const enableTag = (tagId) => {
  const tags = props.activeTags.filter((t) => t !== tagId);
  tags.push(tagId);
  emit('enable', { id: props.category.id, tags });
};

const disableTag = (tagId) => {
  const tags = activeTags.filter((t) => t !== tagId);
  emit('enable', { id: props.category.id, tags });
};
const tagIsActive = (tag) => {
  let active = false;
  activeTags.forEach((t) => {
    if (t == tag.id) {
      active = true;
    }
  });
  return active;
};

</script>
