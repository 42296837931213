<template>
  <base-panel
    :title="poiTitle"
    :item="poi"
    @close="$emit('close')"
  >
    <template #header>
      <p
        v-if="poi.streetAddress !== ''"
        class="text-faded"
      >
        {{ poi.streetAddress }}, {{ poi.zipCode }}
        {{ poi.city }}
      </p>
    </template>
    <template #default>
      <!-- /.notify__department__section -->
      <!-- TODO REMETTRE HORAIRES ICI -->
      <!-- TODO REMETTRE SERVICES ICI -->

      <content-section
        v-if="poiDescription"
        :title="$t(`components.PlaceCard.place.descriptionTitle`)"
        :content="nl2br(poiDescription)"
      />
      <content-section-endgame
        v-if="party && party.endgamePoiId === poi.uuid"
        :party="party"
        :team="currentTeam"
      />
      <content-section-video
        v-if="videoVisibility"
        title="Découvrir en vidéo"
        :url="poi.videoUrl"
        :should-loop="poi.videoLoop"
        :should-autoplay="poi.videoAutoPlay"
      />
      <content-section-audio
        v-if="audioDocuments.length > 0"
        :audio-documents="audioDocuments"
      />
      <Quizz
        v-if="checkinDone && quizzs && quizzs.length > 0"
        :place-id="poi.uuid"
        :quizzs="quizzs"
        :survey-display-mode="poi.surveyDisplayMode"
        @quizzanswered="fetchQuizzs"
      />
      <qrcode-stream
        v-if="isScanning === true"
        :track="paintBoundingBox"
        @decode="onRead"
      />

      <content-section
        v-if="poi.conditions"
        :title="$t('components.PlaceCard.event.conditionsTitle')"
        :content="poi.conditions"
      />
      <content-section
        v-if="pSchedule(poi, lang)"
        :content="nl2br(pSchedule(poi, lang))"
        :title="$t('components.PlaceCard.place.scheduleInfo')"
      />
      <content-section-website
        v-if="poi.websites && poi.websites.length > 0"
        title="Sites internet et réseaux sociaux"
        :content="poi.websites"
      />
      <content-section-contact
        v-if="poi.contacts && poi.contacts.length > 0"
        title="Coordonnées"
        :content="poi.contacts"
      />
    </template>
    <template #footer>
      <panel-poi-btn
        :loading="loading"
        :poi="poi"
        :survey-count="quizzs.length"
        @clicked="onButtonClicked"
      />
    </template>
  </base-panel>
</template>
<script>
import * as Sentry from '@sentry/vue';
import VueScrollTo from 'vue-scrollto';
import Swal from 'sweetalert2';
import ContentSection from './PanelModuleDescription.vue';
import ContentSectionWebsite from './PanelModuleWebsites.vue';
import ContentSectionContact from './PanelModuleContact.vue';
import ContentSectionAudio from './PanelModuleaudio.vue';
import ContentSectionVideo from './PanelModuleVideo.vue';
import ContentSectionEndgame from './PanelModuleEndgame.vue';
import Quizz from './Quizz.vue';
import { poiSchedule } from '../../translationTools/poi';
import  {usePOIManager } from '../../composables/POIManager';

import BasePanel from './BaseDetailsPanel.vue';
import PanelPoiBtn from './PanelPOIBtn.vue';
import quizzApi from '../../api/quizz';
import { useConfigStore } from '../../pinia/configuration';
import { useGameStore } from '../../pinia/game';
import { usePoiStore } from '../../pinia/poi';
import { UseCheckinControl } from '../../composables/CheckinControl';
export default {
  components: {
    ContentSection,
    ContentSectionAudio,
    Quizz,
    BasePanel,
    ContentSectionWebsite,
    ContentSectionContact,
    ContentSectionVideo,
    PanelPoiBtn,
    ContentSectionEndgame,
  },
  props: {
    poi: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'checkinstart', 'checkindone', 'checkincancelled'],
  setup(props) {
    const appConfig = useConfigStore().appConfig;
    const gameStore = useGameStore();
    const poiStore = usePoiStore();
    const { poiTitle, poiDescription, pictures } = usePOIManager(props.poi);
    const { performCheckin, checkinDone } = UseCheckinControl(props.poi);
    return { appConfig, poiTitle, poiDescription, pictures, gameStore, poiStore, checkinDone,  performCheckin };


  },
  data() {
    return {
      quizzs: [],
      loading: false,
      isScanning: false,
    };
  },
  computed: {
    currentTeam() {
      return this.gameStore.currentTeam;
    },
    currenttoken() {
      return this.gameStore.currentToken;
    },
    currentLocation() {
      return this.poiStore.currentLocation;
    },
    party() {
      return this.gameStore.currentParty;
    },
    lang() {
      return this.appConfig.lang;
    },
    audioDocuments() {
      if (this.poi.audioDocuments && Array.isArray(this.poi.audioDocuments)) {
        return this.poi.audioDocuments.filter((audio) => audio.lang.substring(0, 2) === this.lang);
      }
      return [];
    },
    videoVisibility() {
      if (this.poi.videoUrl && this.poi.videoVisibility === 'always') {
        return true;
      }
      if (this.poi.videoUrl && this.checkinDone && this.poi.videoVisibility === 'afterCheckin') {
        return true;
      }
      if (this.poi.videoUrl && this.poi.videoVisibility === 'afterSurvey' && this.quizzs.length === 0) {
        return true;
      }
      return false;
    },
    gpsEnabled() {
      return this.appConfig.enableGeolocation;
    },
    userLocationKnown() {
      return this.userLocation !== null && this.userLocation.length === 2;
    },
    routerLinkRoute() {
      if (this.checkinDone) {
        return {
          route: {
            name: 'placeView',
            params: {
              placeId: this.poi.id,
            },
          },
          label: this.$t('components.PanelContainer.button.checkin_done'),
        };
      }
      return {
        route: {
          name: 'Login',
        },
        label: this.$t('components.PanelContainer.button.checkin_authenticate'),
      };
    },
  },
  watch: {
    async currentTeam(val, oldVal) {
      // oldVal.events can be null so lets check if it exists
      if (!oldVal || !oldVal.events || !Array.isArray(oldVal.events)) {
        if (val && val.events && Array.isArray(val.events) && this.checkinDone) {
          await this.fetchQuizzs();
          return;
        }
      }

      if ((val.events.length > oldVal.events.length) && this.checkinDone) {
        await this.fetchQuizzs();
      }
    },
  },
  async mounted() {
    if (this.checkinDone) {
      await this.fetchQuizzs();
    }
  },
  methods: {
    pSchedule(poi, lang) {
      return poiSchedule(poi, lang);
    },
    nl2br(content) {
      return content.replace(/(\\r)*\\n/g, '<br/>');
    },
    async onButtonClicked(event) {
      switch (event.action) {
        case 'startGPS':
          this.$router.push({ name: 'placeRoutePreview', params: { poiId: this.poi.uuid } });
          break;
        case 'closeCard':
          this.$router.back();
          break;
        case 'login':
          this.pushRoute('Login');
          break;
        case 'register':
          this.pushRoute('Register');
          break;
        case 'checkin':
          await this.onCheckin();
          break;
        case 'quiz':
          VueScrollTo.scrollTo('#quiz-container', 125, { container: '#notify__department__content_scroller' });
          break;
        case 'scan':
          this.isScanning = true;
          break;
        default: break;
      }
    },

    pushRoute(routeName) {
      if (!this.$route.name !== routeName) {
        this.$router.push({ name: routeName });
      }
    },
    async onCheckin() {
      this.$emit('checkinstart');
      const res = await this.performCheckin();
      if (res) {
        this.$emit('checkindone', { poiId: this.poi.uuid, score: this.poi.points });
      } else {
        this.$emit('checkincancelled');
      }
    },
    async onRead(qrCode) {
      if (this.poi.uuid === qrCode.trim()) {
        await this.performCheckin(this.poi);
        this.isScanning = false;
      } else {
        this.isScanning = false;
        Swal.fire({
          title: `${this.$t('components.PanelContainer.error.wrongQR')}`,
          icon: 'error',
        });
      }
    },
    paintBoundingBox(detectedCodes, ctx) {

      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: {
            x, y, width, height,
          },
        } = detectedCode;


        ctx.lineWidth = 2;

        ctx.strokeStyle = '#DA004B';
        ctx.strokeRect(x, y, width, height);
      }
    },
    async fetchQuizzs() {
      this.loading = true;
      try {
        const quizzs = await quizzApi.getQuizz(
          this.appConfig.gameApiBaseUrl,
          this.poi.uuid,
          this.lang,
          this.currentToken,
        );
        if (Array.isArray(quizzs)) {
          quizzs.sort((a, b) => a.order - b.order);
        }
        this.quizzs = quizzs;
        this.loading = false;
      } catch (error) {
        Sentry.captureException(error);
      }
    },
  },


};
</script>
