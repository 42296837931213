<template>
  <div
    id="notify-target"
    class="notify notify--target container lucky-container is-expanded"
  >
    <div
      id="notify__department__content_scroller"
      class="notify__department__content"
    >
      <h2 v-if="!card">
        {{ $t(pfx + 'chanceTitle') }}
      </h2>
      <h2 v-else>
        {{ card.title }}
      </h2>
      <div
        v-if="!card"
        class="logo"
      >
        <img
          :src="`/themes/${theme}/images/chance-img.png`"
          alt="citypoly logo"
        >
      </div>
      <div
        v-else
        class="description"
      >
        <p>
          {{ card.description }}
        </p>
      </div>
    </div>
    <div class="notify__footer">
      <PanelBtn
        v-if="!card"
        :title="$t(pfx + 'pick')"
        :disabled="isLoading"
        @click="pickCard"
      />
      <PanelBtn
        v-else
        :title="btnText()"
        @click="$emit('newAction', card.nextAction)"
      />
    </div>
  </div>
</template>

<script setup>
import * as Sentry from '@sentry/vue';
import PanelBtn from '../map/PanelBtn.vue';
import { ref } from 'vue';
import { getLuckyCard } from '../../api/citypoly';
import { TEAM_ACTIONS } from '../../constants';
import { badNewsConfetti, basicConfetti } from '../../tools/CanvasConfetti';
import { useI18n } from 'vue-i18n';
import { useConfigStore } from '../../pinia/configuration';
import { useGameStore } from '../../pinia/game';

const { t } = useI18n();
const gameStore = useGameStore();
const appConfig = useConfigStore().appConfig;
const emit = defineEmits(['newAction',  'rollDice', 'goToPoi', 'blocked']);
const pfx = 'components.citypoly.PanelLuckyCard.';

const props = defineProps({
  currentTeam: {
    type: Object,
    required: true,
  },
});

const theme = 'citypoly';
const card = ref(null);
const token = gameStore.currentToken;
const isLoading = ref(false);

const btnText = () => {
  switch (card.value.nextAction.type) {
    case TEAM_ACTIONS.ROLL:
      return t(pfx + 'rollDice');
    case TEAM_ACTIONS.GO:
      return t(pfx + 'goTo');
    default:
      return t(pfx + 'rollDice');
  }
};

const fetchCurrentTeamCitypoly = async () => {
  await gameStore.fetchCurrentTeam();

};

const pickCard = async () => {
  isLoading.value = true;
  try {
    const res = await getLuckyCard(appConfig.gameApiBaseUrl, token);
    card.value = res;
    if (card.value.type === TEAM_ACTIONS.BLOCK) {
      emit('blocked');
      badNewsConfetti();
      return;
    }
    await fetchCurrentTeamCitypoly();
    if (card.value.type === TEAM_ACTIONS.SCORE) {
      if (card.value.score > 0) {
        basicConfetti();
        await fetchCurrentTeamCitypoly();
        return;
      }
      if (card.value.score < 0) {
        badNewsConfetti();
      }
    }
    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
    Sentry.captureException(error);
  }
};
</script>

<style lang="scss" scoped>
.lucky-container {
  border-image: linear-gradient(to right, #ffe55e, #ffcf57, #ffba55, #ffa557, #ff915b) 1;
  border-width: 0.5rem;
  border-style: solid;
  min-height: 33vh;
}
h2 {
  text-align: center;
}
.logo {
  width: 100%;
  height: 22vh;
  padding: 2rem;
  & img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.description {
  & p {
    height: 100%;
    font-size: 1.2rem;
    margin-top: 1rem;
    text-align: center;
  }
}
</style>
