<template>
  <div
    v-if="quizzs"
    id="quiz-container"
    class="quizz__container notify__department__section"
  >
    <div
      v-for="quizz in availableQuizzs"
      :key="quizz.id"
    >
      <template v-if="!answeredQuizz.includes(quizz.id)">
        <h3 class="notify__department__title notify__heading quizz__section__title">
          {{ quizz.title }}
        </h3>
        <quizz-form
          :questions="quizz.questions"
          :quizz-id="quizz.id"
          @success="onQuizzAnswered(quizz.id)"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
import Swal from 'sweetalert2';
import QuizzForm from './QuizzForm.vue';
import { useGameStore } from '../../pinia/game';
import { useI18n } from 'vue-i18n';
import { ref, computed } from 'vue';

const emit = defineEmits(['quizzanswered']);
const gameStore = useGameStore();
const {t} = useI18n();

const props = defineProps({
  placeId: {
    type: String,
    required: true,
  },
  quizzs: {
    type: Array,
    required: true,
  },
  surveyDisplayMode: {
    type: String,
    required: true,
  },
});

const answeredQuizz = ref([]);

const availableQuizzs = computed(() => {
  if (props.quizzs.length === 0) {
    return [];
  }
  if (props.surveyDisplayMode === 'one') {
    return [props.quizzs[0]];
  }
  return props.quizzs;
});

const onQuizzAnswered = (quizzId) => {
  Swal.fire({
    title: `${t('components.quizz.quizzAnswer')}`,
    text: '',
    icon: 'info',
  });
  answeredQuizz.value.push(quizzId);
  emit('quizzanswered');
  };
</script>

