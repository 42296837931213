// place types
export const PLACE_TYPE_EVENT = 'event';
export const PLACE_TYPE_POI = 'place';

// distances
export const UNKNOWN_DISTANCE = -1;
export const MAXIMUM_WALKABLE_DISTANCE = 500;

// map zoom levels

export const MAP_ZOOM_LEVEL_EVENTS_PROXIMITY = 12;

export const GPS_MODE_OFF = 'off';
export const GPS_MODE_ON = 'on';
export const GPS_MODE_PREVIEW = 'preview';
export const GPS_MODE_TOUR_PREVIEW = 'tour_preview';

export const UNKNOWN_PLACE_ID = -1;

// map sources and layers names

export const MBX_SOURCE_NAME_PLACES = 'pitc-places';
export const MBX_SOURCE_NAME_GPS = 'pitc-gps';

export const MBX_LAYER_NAME_PLACES = 'places';
export const MBX_LAYER_NAME_GPS = 'gps';

// map modes

export const MAP_MODE_NORMAL = 'MAP_MODE_NORMAL';
export const MAP_MODE_ROUTING = 'MAP_MODE_ROUTING';
export const MAP_MODE_ROUTE_PREVIEW = 'MAP_MODE_ROUTE_PREVIEW';
export const MAP_MODE_TOUR = 'MAP_MODE_TOUR';


// cookie names

export const COOKIE_NAME_TEAM = 'dotmap_team_token';

// citypoly

export const TEAM_ACTIONS  = Object.freeze({
    ROLL: 'roll_dice',
    REJECT: 'reject',
    BUY_CHALLENGE: 'buy_challenge',
    BUY: 'buy',
    PAY: 'pay_rent',
    GO: 'go_to',
    LUCKY: 'lucky_card',
    BLOCK: 'block',
    SCORE: 'score',
    RENT_RECIEVED: 'rent'
})

export const POI_STATUS = Object.freeze({
    AVAILABLE: 'available',
    OWNED_BY_SELF: 'owned-by-self',
    MORTGAGED: 'mortgaged',
    BUYING_IN_PROGRESS: 'buying-in-progress',
    OWNED_BY_OTHER: 'owned-by-other'
})

export const SIMPLE_QUESTION_TYPE = Object.freeze({
    PHOTO: 'photo',
    TEXT: 'text',
    IMAGE: 'image',
    VIDEO: 'youtube',
    AUDIO: 'audio'
})
export const WS_ACTIONS = Object.freeze({
    BUZZ: 'buzz',
    PING: 'ping',
    BROADCAST: 'broadcast'
});
export const PLATFORM_TYPE = Object.freeze({
    CITYPOLY: 'citypoly',
});

export const LOCATION_STATUS = Object.freeze({
    UNKNOWN: 'unknwown',
    OK: 'ok',
    DENIED: 'denied',
    ERROR: 'error',
    TIMEOUT: 'timeout',
});
