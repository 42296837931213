<template>
  <div class="solde-container">
    <h3>
      {{ formattedBalance }} 
    </h3>
    <img
      class="solde-cityflouz-img"
      :src="`/themes/${appConfig.platformType}/images/cityflouz_logo.png`"
      alt="cityflouz img"
    >
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useConfigStore } from '../../pinia/configuration';

const appConfig = useConfigStore().appConfig;

const props = defineProps({
  newBalance: {
    type: Number,
    required: true
  }
})

const displayedBalance = ref(props.newBalance)
const animationDuration = 3000

const formattedBalance = computed(() => {
  return new Intl.NumberFormat('fr-FR', { useGrouping: false }).format(displayedBalance.value);
});

watch(() => props.newBalance, (newVal, oldVal) => {
  animateBalance(oldVal, newVal, animationDuration)
})

function animateBalance(from, to, duration) {
  const startTime = performance.now()
  
  const updateBalance = (currentTime) => {
    const elapsed = currentTime - startTime
    const progress = Math.min(elapsed / duration, 1)
    
    displayedBalance.value = Math.floor(from + (to - from) * progress)
    
    if (progress < 1) {
      requestAnimationFrame(updateBalance)
    }
  }
  
  requestAnimationFrame(updateBalance)
}
</script>

<style lang="scss" scoped>
.solde-container {
    position: fixed;
    top: 30px;
    left: 30px;
    z-index: 12;
    min-width: 4rem;
    min-height: 2rem;
    background-color: #ff915b;
    color: white;
    padding: .5rem;
    line-height: 1;
    font-weight: bold;
    border-radius: 10px;
    border: solid 5px white;
    box-shadow: -1px 0px 10px -3px rgba(0,0,0,0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    }

.solde-cityflouz-img {
  width: 27px;
  height: 27px;
}
</style>