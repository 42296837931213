<template >
  <div class="credits-caption">
    {{$t('components.CreditsCaption.credits', {author: text })}}
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
div.credits-caption {
  position: absolute;
display: flex;
  justify-content: center;
  align-items: center;
    background-color: rgba(0,0,0,0.66);
    box-shadow: 0px 0px 1px rgba(20, 20, 20, 0.40), 0px 1px 1px rgba(0, 0,0, 0.40);
    border-top-right-radius: 5px;
    border: none;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
    z-index: 52;
    font-size: 0.5rem;
    bottom: 0px;
    left: 0px;
    height: 2rem;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 5px;
    padding-right: 5px;
}
</style>
