import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { fetchPois } from '../axiosapi/pois';
import haversine from 'haversine';
import { filterVip, filterDisabledPoi, filterLang, filterCategories } from './poi_filters';
import * as k from '../constants';
import { useDestinationStore } from './destination';
import { useGameStore } from './game';
import { useConfigStore } from './configuration';
import { useCategoriesStore } from './categories';
import geo from '../tools/geo';

export const usePoiStore = defineStore('poi', () => {
  const gameStore = useGameStore();
  const configStore = useConfigStore();
  const destinationStore = useDestinationStore();
  const categoriesStore = useCategoriesStore();
  const pois = ref([]);
  const currentPoi = ref(null); // currently open POI in the app
  const locationWatcher = ref(null); // ID of the location interval watcher
  const mapMode = ref(k.MAP_MODE_NORMAL);
  const currentLocation = ref(null); // current position of the user
  const lastLocationUpdate = ref(null); // date of the last position update
  const locationStatus = ref(k.LOCATION_STATUS.OK); // status of the location watcher
const locationWatcherMonitor = ref(null);
  const activePois = computed(() => {
    const currentTeam = gameStore.currentTeam;
    const lang = configStore.appConfig.lang;
    const sorter = (a, b) => a.distance - b.distance;
    const disabledPois = gameStore.disabledPlaces;

    // this filter could be made shorter but I want to keep it readable
    // please don't try to refactor it
    const filteredPois = pois.value.filter((p) => {
      if (!filterLang(p, lang)) {
        return false;
      }
      if (!filterCategories(p, categoriesStore.activeCategories)) {
        return false;
      }
      if (!filterVip(p, currentTeam)) {
        return false;
      }
      if (!filterDisabledPoi(p, disabledPois)) {
        return false;
      }
      return true
    });

    // if the team is connected, update the icon of the POI if it was checked in
    let verifyCheckins = false;
    if (currentTeam !== null) {
      verifyCheckins = true;
    }
      const checkins = gameStore.checkinsByPOI;
      const checkedInPois = filteredPois.map((p) => {
        if (verifyCheckins === true) {
          const checkin = checkins[p.uuid];
          if (checkin) {
            return { ...p, icon: 'checkedin' };
          }
          return { ...p};
        }
        return { ...p};
      });

    // get the distance between the POIs and the current location
      if (currentLocation.value) {
        const poisWithDistance = checkedInPois.map((p) => {
          const distance = haversine(currentLocation.value, { latitude: p.latitude, longitude: p.longitude }, { unit: 'meter' });
          return { ...p, distance };
        });
        poisWithDistance.sort(sorter);
        return poisWithDistance;
      }
      return checkedInPois;
  });

  const poisObject = computed(() => {
    const  o = {};
    activePois.value.forEach((p) => {
      o[p.uuid] = p;
    });
    return o
  });

  const walkablePois = computed(() => {
    return activePois.value.filter((p) => p.distance > k.UNKNOWN_DISTANCE && p.distance <= k.MAXIMUM_WALKABLE_DISTANCE);
  });

  const mapBounds = computed(() => {
      let pois = [];
      if (walkablePois.value.length > 1) {
        pois = [...walkablePois.value];
      } else {
        pois = [...activePois.value];
      }
      if (currentLocation.value && currentLocation.value.latitude && currentLocation.value.longitude) {
        if ( !isNaN(currentLocation.value.longitude) && !isNaN(currentLocation.value.latitude)) {
          const userLocation = { coordinates: currentLocation };
          pois.push(userLocation);
      }
     }
    if (!pois || pois.length < 2) {
      return [];
    }
    return geo.lngLatBounds(pois);
  });

//  const poisAsGeoJSON = computed(() => { });
//  const closestPoi = computed(() => { });


  const getPois = async () => {
    const res = await fetchPois(configStore.appConfig.cmsApiBaseUrl, destinationStore.currentDestination.id);
    pois.value = res;
    return res;
   };
  const startLocationWatcher = () => {
    if ('geolocation' in navigator) {
      const locationParams = {
        enableHighAccuracy: true,
        maximumAge: configStore.appConfig.locationUpdateInterface,
        timeout: configStore.appConfig.locationUpdateInterface,
      };
      locationWatcher.value = navigator.geolocation.watchPosition(
        (newPos) => {
          updateLocation(newPos.coords);
        }, (error) => {
          if (error.code !== 3) {
            handleLocationError(error);
          }
        }, locationParams,
      );
      startLocationWatcherMonitor();
   }
  };

  const updateLocation = (coords) => {
    currentLocation.value = { latitude: coords.latitude, longitude: coords.longitude };
    lastLocationUpdate.value = new Date();
    locationStatus.value = k.LOCATION_STATUS.OK;
  }

  const handleLocationError = (error) => {
    if (!error.code) {
      locationStatus.value = k.LOCATION_STATUS.ERROR;
      return;
    }
    switch (error.code) {
      case 1:
        locationStatus.value = k.LOCATION_STATUS.DENIED;
        break;
      case 2:
        locationStatus.value = k.LOCATION_STATUS.UNKNOWN;
        break;
        case 3:
        locationStatus.value = k.LOCATION_STATUS.TIMEOUT;
      default:
        locationStatus.value = k.LOCATION_STATUS.ERROR;
      break;
  };
};
  const stopLocationWatcher = () => {
    //shutdown the monitor before the watcher to avoid false stall-detections
    if (locationWatcherMonitor.value) {
      stopLocationWatcherMonitor();
    }
    if (locationWatcher.value) {
      navigator.geolocation.clearWatch(locationWatcher.value);
    }
   };
  const restartLocationWatcher = () => {
    stopLocationWatcher();
    startLocationWatcher();
  };

  // the monitor ensure that the location watcher is not stalled
  const startLocationWatcherMonitor = () => {
    console.log('Starting location watcher monitor');
    locationWatcherMonitor.value = setInterval(() => {
      if (lastLocationUpdate.value) {
        const now = new Date();
        const diff = now - lastLocationUpdate.value;
        if (diff > (configStore.appConfig.locationUpdateInterface +15)) {
          locationStatus.value = k.LOCATION_STATUS.TIMEOUT;
          console.log('Location watcher stalled. Restarting');
          restartLocationWatcher();
        }
      }
    }, configStore.appConfig.locationUpdateInterface);
   }
  const stopLocationWatcherMonitor = () => {
    clearInterval(locationWatcherMonitor.value);
   }

  return {
    pois,
    poisObject,
    currentPoi,
    mapMode,
    activePois,
    walkablePois,
    mapBounds,
    currentLocation,
    locationStatus,
    getPois,
    startLocationWatcher,
    stopLocationWatcher
  };
});

