<template>
  <div class="initial-loading">
    <p>
      <img
        :src="logoURL"
        height="40px"
        alt="Platform logo"
      >
    </p>
    <p><img src="/img/rings.svg"></p>
    <p>Préparation en cours...</p>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useConfigStore } from '../../pinia/configuration';


const store = useConfigStore();

const logoURL = computed(() => {

  if (store.appConfig.logoUrl && store.appConfig.logoUrl.length > 1) {
    return store.appConfig.logoUrl;
  }
  return '/images/logos/dotmap.png';
});

</script>

<style scoped>
.initial-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin:0 auto;
  text-align: center;
  padding-top: 20%;
  color:#FFF;
}
.initial-loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #FFF #FFF transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
</style>
