  /**
   * tests if the POI is a VIP POI and if the user has enough points to see it
   *
   * @param {cms.POI} poi  to test
   * @param {*} team the team  object as stored in the game store
   * @returns bool
   */
  export const filterVip = (poi, team) => {
    if (poi.requiresMinimumBalance === false) {
      return true;
    }
    if (team === null) {
      return false;
    }
    if (team.score < poi.minimumBalance) {
      return false;
    }
    return true;
  }

  /**
   * tests if the poi is in the disabled places
   * @param {*} poi
   * @param {*} disabledPlaces array of disabled place ids for the current party/team
   * @returns bool
   */
  export const filterDisabledPoi = (poi, disabledPois) => {
    if (!disabledPois) {
      return true;
    }
    if (disabledPois.includes(poi.uuid)) {
      return false;
    }
    return true;
  }

  /**
   * tests if the poi has a translation in the {lang} language
   * @param {*} poi
   * @param {*} lang
   * @returns bool
   */
  export const filterLang = (poi, lang) => {
    const locales = Object.keys(poi.locales);
    const shortLocales = locales.map((l) => l.substring(0, 2));
    return shortLocales.includes(lang);
  }

  /**
   * tests if the poi is linked to the { categories } array
   * @param {*} poi
   * @param {*} categories
   */
  export const filterCategories = (poi, categories) => {
    const categoryIds = categories.map((c) => c.id);
    if (categoryIds.length === 0) {
      return true;
    }
    if (categoryIds.includes(poi.categoryId)) {
      return true;
    }
    if (poi.categoryIds && poi.categoryIds.length > 0) {
      if (poi.categoryIds.some((c) => categoryIds.includes(c))) {
        return true;
      }
    }
    return false;
  };
