
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { ref } from 'vue';
import { defineStore } from 'pinia';
axiosRetry(axios, { retries: 3 });

export const useConfigStore = defineStore('config', () => {
  const appConfig = ref({})

  const fetchConfig = async () => {
    const res  = await axios.get('/api/v2/config');
    appConfig.value = res.data;
    appConfig.value.lang = 'fr';
    return res.data;
  }
  return {
    appConfig,
    fetchConfig
  };
});
