<template>
  <div class="btn-close" />
</template>
<script>

</script>
<style lang="scss" scoped>
.btn-close {
  height: 25px;
  width: 25px;
  position: relative;
  background-color: #da004b;
  cursor: pointer;
  border-radius: 50%;
  transition: transform .4s ease-in-out;
  &::before, &::after {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 20px;
    width: 2px;
    background-color: #ffffff;
    border-radius: 2px;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
  &:hover{
    transform: rotate(90deg);
  }
}
</style>
