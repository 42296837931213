import * as imageConversion from 'image-conversion';
import * as Sentry from '@sentry/vue';
import http from './fetch';
// import quizzMock from './quizz_mock';

export default {
  getQuizz(apiGameBaseURL, poiUuid, lang, token) {
    return new Promise((resolve, reject) => {
      // resolve(quizzMock);
      const url = `${apiGameBaseURL}/gameapi/v1/surveys?poi_id=${poiUuid}&lang=${lang}`;
      http
        .getJSON(url, {}, token)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  postAnswer(apiGameBaseURL, quizzId, answer, token) {
    return new Promise((resolve, reject) => {
      const url = `${apiGameBaseURL}/gameapi/v1/surveys/${quizzId}/answer`;
      http
        .postJSON(url, {}, answer, token)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  postImage(apiGameBaseURL, image, teamId, token) {
    return new Promise((resolve, reject) => {
      const url = `${apiGameBaseURL}/gameapi/v1/pictures?team_id=${teamId}`;
      let img = null;
      imageConversion.compressAccurately(image, 500).then((compressedImage) => {
        img = compressedImage;
      }).catch((error) => {
        img = image;
        Sentry.captureException(error);
      }).finally(() => {
        http
          .postImage(url, {}, img, token)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  },
};
