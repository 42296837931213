import Swal from 'sweetalert2';

// toast alert qui prend une icon et un titre
// toast.fire({icon: 'foo', title: 'bar'})
const toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
});

export default toast;
