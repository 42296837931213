import { createRouter, createWebHashHistory } from 'vue-router';
import MapView from './views/Map.vue';
import ng from './navigationGuards';
import CitypolyWelcome from './views/CitypolyWelcome.vue';

const DashboardView = () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue');
const HowView = () => import(/* webpackChunkName: "how" */ './views/How.vue');
const LegalView = () => import(/* webpackChunkName: "legal" */ './views/Legal.vue');
const RegisterGameView = () => import(/* webpackChunkName: "registerGame" */ './views/RegisterGame.vue');
const LoginGameView = () => import(/* webpackChunkName: "loginGame" */ './views/LoginGame.vue');
const DebugView = () => import(/* webpackChunkName: "debug" */ './views/Debug.vue');

// permanentRoutes stores the routes that can't be conditionnally disabled
function permanentRoutes() {
  return [
    {
      path: '/',
      name: 'home',
      component: MapView,
    },
    // Old routes redirected to a newer one
    {
      path: '/guide/place/:placeId',
      name: 'oldPlaceView',
      redirect: '/pois/:placeId',
      alias: '/guide/place/:placeId/type/:type',
    },
    // current routes
    {
      path: '/pois/:placeId',
      name: 'placeView',
      component: MapView,
    },
    {
      path: '/pois/:poiId/preview',
      name: 'placeRoutePreview',
      component: MapView,
    },
    {
      path: '/pois/:poiId/route',
      name: 'placeRoute',
      component: MapView,
    },
    {
      path: '/how-it-works',
      name: 'How',
      component: HowView,
    },
    {
      path: '/legal',
      name: 'Legal',
      component: LegalView,
    },
    {
      path: '/dbg',
      name: 'Debug',
      component: DebugView,
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/',
    },
  // cityPoly
  {
    path: '/citypoly-welcome',
    name: 'citypolyWelcome',
    component: CitypolyWelcome,
  },
];
}

// initRouter creates a router with the permanentRoutes and the routes that have been enabled in the
// config object
function initRouter(config) {
  const routes = permanentRoutes(config);

  // add all account related routes
  if (config.enableLogin === true) {
    routes.push(
      {
        path: '/login',
        name: 'Login',
        component: LoginGameView,
        beforeEnter: (to, from, next) => {
          if (config.enableLogin === false) {
            next('/');
          } else {
            next();
          }
        },
      },
      {
        path: '/account',
        name: 'Account',
        component: DashboardView,
        beforeEnter: ng.UserAuthenticated,
      },
    );
  }

  // add registration route
  if (config.enableRegistration === true) {
    routes.push(
      {
        path: '/register',
        name: 'Register',
        component: RegisterGameView,
        beforeEnter: (to, from, next) => {
          if (config.enableRegistration === false) {
            next('/');
          } else {
            next();
          }
        },
      },
    );
  }
  const router = createRouter({
    routes,
    history: createWebHashHistory(),
  });
  return router;
}

export default initRouter;
