<template>
  <div class="slider slider--locations notify__slider notify__header-mobile">
    <div class="slider__clip">
      <ul class="slider__slides">
        <picture-btn
          icon="close"
          :text="$t('common.buttons.picture_close')"
          @btnpressed="$emit('close')"
        />
        <picture-btn
          icon="fullscreen"
          :text="$t('common.buttons.picture_fullscreen')"
          @btnpressed="openLightbox"
        />

        <credits-caption
          v-if="currentPicture &&
            currentPicture.author &&
            currentPicture.author.length > 0"
          :text="currentPicture.author"
        />
        <carousel
          :items-to-show="1"
          @slideend="onSlideEnd"
        >
          <slide
            v-for="(picture, indx) in pictures"
            :key="indx"
            :index="picIndex"
          >
            <li class="slider__slide">
              <lightbox-img
                :base-width="375"
                :picture-path="picture"
                :alt-text="picture.altText ? picture.altText: null"
                @close="$emit('close')"
              />
            </li>
          </slide>
          <template #addons>
            <navigation />
          </template>
        </carousel>
      </ul>
    </div>
    <div class="slider__paging slick-dots-alternative" />
  </div><!-- end slider -->
</template>

<script setup>
import {onMounted, ref, computed } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import PictureBtn from '../image/PictureBtn.vue';
import CreditsCaption from '../image/CreditsCaption.vue';
import { usePOIManager } from '../../composables/POIManager';
import { useUIStore } from '../../pinia/uistate';


const uiStore  = useUIStore();
const props = defineProps({
  item: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  imagesPath: {
    type: String,
    required: false,
    default: '',
  },
});
const { pictures } = usePOIManager(props.item);

const emits = defineEmits(['close']);

// index du slide actuel dans le carousel
const picIndex = ref(0);

const currentPicture = computed(() => pictures.value[picIndex.value]);

onMounted(() => {
  picIndex.value = 0;
});

const onSlideEnd = (event) => {
  picIndex.value = event.currentSlideIndex;
};

const openLightbox = () => {
  uiStore.lightboxPicture = { pictures: pictures.value, index: picIndex.value };
};
</script>
