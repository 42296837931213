/* eslint-disable prefer-destructuring */
import has from 'lodash.has';

const lngLatBounds = (locations) => {
  if (!Array.isArray(locations)) {
    throw new Error('invalid argument: lngLatBounds() expects an array');
  }
  if (locations.length < 2) {
    return [];
  }
  let minLat = locations[0].latitude;
  let minLong = locations[0].longitude;
  let maxLat = locations[0].latitude;
  let maxLong = locations[0].longitude;

  locations.forEach((l) => {
    if (minLat > l.latitude) {
      minLat = l.latitude;
    }
    if (minLong > l.longitude) {
      minLong = l.longitude;
    }
    if (maxLat < l.latitude) {
      maxLat = l.latitude;
    }
    if (maxLong < l.longitude) {
      maxLong = l.longitude;
    }
  });
  return [[minLong, minLat], [maxLong, maxLat]];
};

const bounds = (locations) => {
  console.log('bounds is deprecated');
  let minLat = null;
  let maxLat = null;
  let minLong = null;
  let maxLong = null;
  if (!Array.isArray(locations)) {
    throw new Error('invalid argument: bounds() expects an array');
  }
  if (locations.length < 2) {
    throw new Error('invalid arguments: bounds() needs at least 2 locations');
  }
  minLat = locations[0].latitude;
  minLong = locations[0].longitude;
  maxLat = locations[0].latitude;
  maxLong = locations[0].longitude;
  locations.forEach((l) => {
    if (has(l, 'latitude') && has(l, 'longitude')) {
      if (minLat > l.latitude) {
        minLat = l.latitude;
      }
      if (minLong > l.longitude) {
        minLong = l.longitude;
      }
      if (maxLat < l.latitude) {
        maxLat = l.latitude;
      }
      if (maxLong < l.longitude) {
        maxLong = l.longitude;
      }
    }
  });
  return [{ latitude: minLat, longitude: minLong }, { latitude: maxLat, longitude: maxLong }];
};
export default {
  bounds,
  lngLatBounds,
};
