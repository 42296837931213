<template>
  <div
    id="notify-target"
    class="notify notify--target container"
    :class="isExpanded ? 'is-expanded' : ''"
  >
    <div
      id="notify__department__content_scroller"
      class="notify__department__content"
    >
      <div class="logo">
        <img
          :src="`/themes/${theme}/images/citypoly_logo.svg`"
          alt="citypoly logo"
        >
      </div>
      <div
        v-if="currentTeam"
        class="welcome"
      >
        <h2>
          {{ teamName }}
        </h2>
      </div>
    </div>
    <div class="notify__footer">
      <PanelBtn
        :title="$t(pfx + 'start')"
        @click="redirectWithAction"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import PanelBtn from '../../components/map/PanelBtn.vue';
import { TEAM_ACTIONS } from '../../constants';
import { usePoiStore } from '../../pinia/poi';

const { t } = useI18n();
const poiStore = usePoiStore();
const emit = defineEmits(['displayDicePannel', 'goToPoi', 'displayLuckyCard']);

const pfx = 'components.citypoly.PannelCitypolyWelcome.';

const props = defineProps({
  expanded: {
    type: Boolean,
    required: true,
  },
  currentTeam: {
    type: Object,
    required: true,
  },
});

const isExpanded = ref(props.expanded);
const theme = 'citypoly';

const teamName = computed(() => {
  if (props.currentTeam) {
    return t(pfx + 'welcome', { team: props.currentTeam.name });
  }
  return t(pfx + 'notConnected');
});

const redirectWithAction = () => {
  if (!props.currentTeam) {
    return;
  }
  switch (props.currentTeam.currentAction.type) {
    case 'roll_dice':
      isExpanded.value = false;
      emit('displayDicePannel');
      break;
    case 'go_to':
      isExpanded.value = false;
      emit('goToPoi', props.currentTeam.currentAction);
      break;
    case 'buy_challenge':
      poiStore.currentPoi = poiStore.activePois.find((p) => {
        return p.uuid === props.currentTeam.currentAction.targetId;
      });
      isExpanded.value = false;
      emit('goToPoi', props.currentTeam.currentAction);
      break;
    case 'buy':
    poiStore.currentPoi = poiStore.activePois.find((p) => {
        return p.uuid === props.currentTeam.currentAction.targetId;
      });
      isExpanded.value = false;
      emit('goToPoi', props.currentTeam.currentAction);
      break;
    case TEAM_ACTIONS.PAY:
    poiStore.currentPoi = poiStore.activePois.find((p) => {
        return p.uuid === props.currentTeam.currentAction.targetId;
      });
      isExpanded.value = false;
      emit('goToPoi', props.currentTeam.currentAction);
      break;
    case TEAM_ACTIONS.LUCKY:
      isExpanded.value = false;
      emit('displayLuckyCard');
      break;
    default:
      break;
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient(to right, #ffe55e, #ffcf57, #ffba55, #ffa557, #ff915b);
}

.logo {
  width: 100%;
  height: 22vh;
  padding: 3rem 3rem 0rem 3rem;
  & img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.welcome {
  width: 100%;
  text-align: center;
  padding: 3rem 1rem 3rem 1rem;
  color: white;
}

.btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
